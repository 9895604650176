import React, { Component } from "react";

import '../Main.css';
import { Container, Row, Table, Button} from 'react-bootstrap';

import axios from "axios";

import Cookies  from 'js-cookie'
import {decode} from 'string-encode-decode'

import  Navbar  from '../components/Navbar';
import  Navbar_Bottom  from '../components/Navbar_bot';

import Footer from "../components/Footer";

import API from "../utils/PlatformAPI";

class BetsHistory extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
        tableData: [],
        someData: ['1','2','3','576'],
        isLoading: true,
        errors: null
      }
    }

  
  getBetsHistory() {
    var self = this
    const axios = require('axios')
    const options = {
        headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
    };
    // bodyFormData.append('User_ID', '1');
    axios.get(API + '/get_bets_history/', options)
        .then(response => self.setState({ tableData: JSON.parse(JSON.stringify(response.data.detail))}));
    //    this.setState({tableData: JSON.stringify(response.data.Response[0][0]}))

        

}

    componentDidMount() {
        this.getBetsHistory()
    }

    

    render() {
    return (
      <div style={{backgroundColor: "#323C44",margin: "0"}}>
       <Navbar /> 
       <Navbar_Bottom />

<div style={{textAlign: "center"}}>
       <h1>MY BETS</h1>
       </div>
<br/>
<div style={{overflowX: "auto",marginRight: "15px"}}>
<Container>


{this.state.tableData.length > 0 ?
<Table striped hover variant="dark" className="user_table" >
                                        <thead >
                                            <tr>
                                            <th style={{color: "#FBC02D"}}>ID</th>
                                                <th>DATE</th>
                                                <th>SECTION</th>
                                                <th>BET TYPE</th>
                                                <th>AMOUNT</th>
                                                <th>CURRENCY</th>
                                                <th>GAME ID</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody >
                                        {
      this.state.tableData
        .map(function (data) {
            return (
            <tr>
            <td>{data[0]}</td>
            <td>{data[6]}</td>
            <td>{data[1]}</td>
            <td>{data[3]}</td>
            <td>{data[2]}</td>
            <td>{data[4]}</td>
            <td>{data[5]}</td>
            
        </tr>
            );
        })
    }  

                                        </tbody>
                                    </Table>
    
 : <h2>Your history is empty</h2>}
                            
                                    </Container>
                                    </div>
       <Footer />
       </div>

    );
  }
  }
  
  export default BetsHistory;