import React from "react";
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import { Nav,Row,Col,Tab} from 'react-bootstrap';


export default class RouteNotFound extends React.Component {
  render() {
      return(
        <div className="mainContent" style={{backgroundColor: "#282c34",textAlign: "center"}}>
          <Navbar />
            <Navbar_Bottom />
          <div style={{minHeight: "550px",height: "25%"}}>
          <figure>
	<div className="sad-mac" style={{marginTop: "3%",marginBottom: "3%"}}></div>
	<figcaption >
		<span className="sr-text">Error 404: Not Found</span>
		<span className="e"></span>
		<span className="r"></span>
		<span className="r"></span>
		<span className="o"></span>
		<span className="r"></span>
		<span className="_4"></span>
		<span className="_0"></span>
		<span className="_4"></span>
    <br/><br/>
		<span className="n"></span>
		<span className="o"></span>
		<span className="t"></span>
		<span className="f" style={{marginLeft: "10px"}}></span>
		<span className="o" style={{marginLeft: "10px"}}></span>
		<span className="u" style={{marginLeft: "10px"}}></span>
		<span className="n" style={{marginLeft: "10px"}}></span>
		<span className="d" style={{marginLeft: "10px"}}></span>

	</figcaption>
</figure>
</div>
        <Footer/>
        </div>
      )

  }

}
