import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";

import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import Casino from './pages/Casino';
import LiveCasino from './pages/LiveCasino';

import VirtualGame from './pages/VirtualGame';
import LiveGame from './pages/LiveGame';

import VirtualSports from './pages/VirtualSports';
import Homepage from './pages/Homepage';
import Register from './components/Register';
import Promotions from './pages/Promotions';

import MyProfile from './pages/MyProfile';
import TransactionsHistory from './pages/TransactionsHistory';
import BetsHistory from "./pages/BetsHistory";

import TermsAndConditions from './pages/TermsAndConditions';
import RulesAndRegulations from './pages/RulesAndRegulations';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SearchResultsPage from "./pages/SearchResultsPage";
import Sportsbook from "./pages/Sportsbook";
import Live_In_Play from "./pages/Live_In_Play";
import AboutUs from "./pages/AboutUs";
import ContuctUs from "./pages/Contacts";
import RouteNotFound from "./pages/RouteNotFound";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.css';


class Root extends Component {

  
  render() {
    document.title = "Betdome"
    return (
      
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/casino" component={Casino} />
            <Route path="/live_casino" component={LiveCasino} />
            

            <Route path="/sportsbook" component={Sportsbook} />
            <Route path="/live_in_play" component={Live_In_Play} />

            <Route path="/my_profile" component={MyProfile} />
            <Route path="/bets_history" component={BetsHistory} />
            <Route path="/transactions_history" component={TransactionsHistory} />

            <Route path="/register" component={Register} />

            <Route path="/virtual_game" component={VirtualGame} />
            <Route path="/live_game" component={LiveGame} />

            <Route path="/virtual_sports" component={VirtualSports} />
            <Route path="/promotions" name="PROMOTIONS" component={Promotions} />
            <Route path="/search_results" component={SearchResultsPage} />
            <Route path="/terms_and_coditions" component={TermsAndConditions} />
            <Route path="/rules_and_regulations" component={RulesAndRegulations} />
            <Route path="/privacy_policy" component={PrivacyPolicy} />
            <Route path="/about_us" component={AboutUs} />
            <Route path="/contact_us" component={ContuctUs} />
            <Route component={RouteNotFound} />
          </Switch>
        </BrowserRouter>

      </Provider>
    );
  }
}

export default Root;
