import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import { Nav,Row,Col,Tab} from 'react-bootstrap';


class TermsAndConditions extends React.Component {
    constructor(props) {
        super(props)
		this.state = {
        }
    }


    componentDidMount() {
    }

    render() {
        return (
            <>
            <Navbar />
            <Navbar_Bottom />

            <div className="mainContent" style={{backgroundColor: "#282c34",textAlign: "center"}}>
	
		<Tab.Container id="left-tabs-example" defaultActiveKey="one">
  <Row>
    <Col sm={3} >
    <div style={{borderRight: "1px solid #979797"}}>
      <Nav variant="pills" className="flex-column"  activeKey="/one">
		  <div style={{marginTop: "15px"}} >
        <Nav.Item className="vert_tab">
          <Nav.Link eventKey="one" className="vert_tab">1. General Terms and Conditions</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="two" className="vert_tab">2. Account Rules</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="three" className="vert_tab">3. Betting rules</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="four" className="vert_tab">4. Policy of site use</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="five" className="vert_tab">5. Glossary</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="six" className="vert_tab">6. Policy of Bets</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="seven" className="vert_tab">7. Displaying Data</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="eight" className="vert_tab">8. Interlocking events and outcomes</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="nine" className="vert_tab">9. Obvious and technical errors</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="ten" className="vert_tab">10. Bets on credit</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="eleven" className="vert_tab">11. Legal Aspects</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twelve" className="vert_tab">12. Restrictions on rates</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="thiteen" className="vert_tab">13. Late bets</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="fourteen" className="vert_tab">14. Return</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="fifteen" className="vert_tab">15. Privacy of User’s data</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="sixteen" className="vert_tab">16. Match-fixing</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="seventeen" className="vert_tab">17. Neutral field</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="eighteen" className="vert_tab">18. Interrupted events</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="nineteen" className="vert_tab">19. Events transfer</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twenty" className="vert_tab">20. Calculation of bets</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentyone" className="vert_tab">21. Betting groups/syndicates and Match/Event limit</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentytwo" className="vert_tab">22. Non-participation in the event</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentythree" className="vert_tab">23. Rules on sports</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentyfour" className="vert_tab">24. Registration</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentyfive" className="vert_tab">25. Currency</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentysix" className="vert_tab">26. The bonus program</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentyseven" className="vert_tab">27. Deposits</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentyeight" className="vert_tab">28. Entry of money on account</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="twentynine" className="vert_tab">29. Financial Limitations</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="thirty" className="vert_tab">30. Payments / Withdrawals</Nav.Link>
        </Nav.Item>
        </div>
      </Nav>
      </div>
    </Col>
    <Col sm={9} style={{marginLeft: "-20px"}}>
	{/* <h1>Terms of Service</h1> */}
  <Tab.Content style={{marginTop: "15px"}}>
        <Tab.Pane eventKey="one">
    <p>Da Bomb Betting may update, amend, edit and supplement the Da Bomb Betting Rules at any time. Any substantial amendment to the Da Bomb Betting Rules shall be notified in advance by Da Bomb Betting to the Account Holder, before coming into effect and will require your consent. If you do not agree with the amendment(s) to the Da Bomb Betting rules, you will not be permitted to continue using the Services. All references in the Da Bomb Betting Rules to the singular shall include the plural and vice versa. All references to “the” shall include “a/an” and vice versa.</p>
	<h2>General Introduction, definitions and stipulations</h2>
	<p>
<b>1.1</b> An “Account Holder” is an individual having a contractual relationship with Da Bomb Betting by creating a Da Bomb Betting Account on the Website.
</p>
<p>
<b>1.2</b> A “Da Bomb Betting Account” is an account held by an Account Holder, for bona fide transactions, with a strict aim to establish a normal commercial relationship with Da Bomb Betting and with the strict purpose of conducting betting and other gaming and gambling transactions.</p>
<p>
<b>1.3</b> The “Contract”is the contractual relationship between Da Bomb Betting and an Account Holder and shall be constituted under and governed by the Da Bomb Betting Rules, i.e. the “Terms and Conditions”.</p>
<p>
<b>1.4</b> The “Website”is the internet gateway accessible through the internet address www.DaBombBetting.com where all current and relevant information regarding Da Bomb Betting operations is published, and through which the Services are provided to the Account Holders.
</p>
<p>
<b>1.5</b> The “Services” are the gaming and betting offers provided by Da Bomb Betting to the Account Holder through the Website.</p>
<p>
<b>1.6</b> A “Card” refers to all types of cards with a function of “payment”, “charge”, “debit”, “credit”, “virtual” and/or similar.</p>
<p>
<b>1.7</b> A “Payment Solution Provider” is an intermediary acting as a payment agent for various different payment methods.</p>
<p>
<b>1.8</b> A “Financial Institution” is a bank and/or other institution regulated by an applicable domestic financial services Act or similar.</p>
<p>
<b>1.9</b> “Force Majeure” refers to any occurrence or condition beyond one’s reasonable control which leads to a delay or default in the performance of the affected party’s contractual obligation and shall, for the purposes of the Da Bomb Betting Rules, include Acts of God, government restrictions (including the denial or cancellation of any necessary license where such denial or cancellation is made through no fault of the affected party), wars, outbreak of hostilities, riots, civil disturbances, insurrections, acts of terrorism, fire, explosions, floods, theft, malicious damage, strikes, lockouts, and/or any other cause beyond the reasonable control of the party whose performance is affected.</p>
<p>
<b>1.10</b> “Client Application” shall mean the web browser application opened by the Account Holder in order to use the Services.</p>
<p>
<b>1.11</b> “Software” shall mean all software used by Da Bomb Betting to offer and/or operate the Services and/or run the Client Application.</p>
<p>
<b>1.12</b> A “Prize” is an amount, a bonus or a reward that can be won by the Account Holder.</p>
<p>
<b>1.13 </b>An “Inactive Account” is a Da Bomb Betting Account which has not recorded any log-in and/or log-out for 
a period exceeding 12 consecutive months.</p>
<p>
<b>1.14</b> Bets are cancelled if there is an inaccuracy in the names of players or teams, if the wrong odds are offered, if a wrong total is quoted, if a wrong handicap is quoted, etc. In these instances the bets are void.</p>
<p>
<b>1.15</b> All trademarks, service marks and trade names as well as images, graphics, text, concepts or methodologies (collectively the “Intellectual Assets” ) found on the Website, the Client Application and the material contained therein are the exclusive property of Da Bomb Betting and/or Da Bomb Betting's suppliers and partners. The Account Holder is not entitled to exploit or otherwise use any Intellectual Asset for whatever purpose, except for what is allowed by law.
</p>
        </Tab.Pane>
        <Tab.Pane eventKey="two">
		<h2>Creation of a Da Bomb Betting Bet Account</h2>
		<p>
<b>2.1.1</b> An individual cannot participate in a game for money unless that individual is an Account Holder. To be registered as a player, an individual must register personally and submit an application for registration. The following information must be provided:
- date of birth and provide valid identification showing that the player is over eighteen (18) years of age or the applicable legal age of majority as stipulated in the jurisdiction of your residence (identification documents which must be submitted include: copy of a valid identity card, passport or driving license);
- player’s first and last name;
- player’s full residential address;
- player’s valid email address;
- a username and a password.
</p>
<p>
<b>2.1.2</b> An individual applying to become an Account Holder warrants and represents not to be under the age of eighteen (18) years. Minors cannot register as a player and cannot hold a Da Bomb Betting Account. Da Bomb Betting reserves the right to request additional proof of age and perform additional checks in order to verify the information provided. A Da Bomb Betting Account may be suspended until satisfactory proof of age is provided.
</p>
<p>
<b>2.1.3</b> An individual applying to become an Account Holder furthermore warrants and represents:<br/>
- to be a physical person (a legal entity will not be accepted as an Account Holder);<br/>
- not to be a professional player in any sport, competition or league where Da Bomb Betting offers betting;<br/>
- not to be restricted by limited legal capacity;<br/>
- not to be acting on behalf of another party;<br/>
- not to be classified as a compulsive problem gambler, and/or be included (whether voluntarily or 
involuntarily) on any register or database of excluded players;<br/>
- not to be depositing monies originating from criminal and/or other illegal activities;<br/>
- not to be depositing monies through a Card which the Account Holder is not authorized to use and/or utilizing a Card in a jurisdiction in which betting and gaming are prohibited;<br/>
- not to be conducting criminal activities whereby a Da Bomb Betting Account is directly or indirectly involved;<br/>
- not to use the Services if it is illegal in his/her country of residence or otherwise restricted for him/her to open a gaming account, purchase or use services from Da Bomb Betting and/or otherwise participate in the games offered. It is the Account Holder’s responsibility to ensure his/her use of Da Bomb Betting's Website and Services is legal;<br/>
- not to find the Website or the Services offensive, objectionable, unfair, nor indecent; and to maintain his/her Da Bomb Betting Account details up-to-date in terms of the following: first and last name, country of residence, valid email address and phone number.<br/>
</p>
<p>
<b>2.1.4</b> An individual applying for a Da Bomb Betting Account acknowledges and accepts:<br/>
- all definitions and stipulations as stated under the Da Bomb Betting Rules;<br/>
- the Da Bomb Betting Rules as currently published on the Website, as well as any possible future changes to the Da Bomb Betting Rules;<br/>
- that the Contract is governed by:<br/>
(i) the Da Bomb Betting Rules as published in English;<br/>
(ii) any regulatory provisions or other decisions made from time to time by the Governing Authorities; and <br/>
(iii) the Governing Law of the Place of the Contract.<br/>
<br/>

</p>
<p>
<h2>Rules of acceptance of bets on sports events</h2>
<b>2.2</b> The Organizer has a right to cancel any bonus, jackpot and/or any prize if the Organizer is confident the Player has implemented a system usage fraud. In this case the Organizer freezes the Player's special gaming account, and if there are any funds, makes a refund to the payment system that was used by the Player to replenish the gaming account.
</p>
<p>
<h2>Arranged game</h2>

<b>2.3</b> If the Organizer has sufficient facts to suspect the case of an arranged game, they can terminate the prize remunerations and make a re-payment of the already carried out payments to the Player's special gaming accounts. The mentioned suspicions can include data referring to sums and characteristics, as well as amounts of all entry payments made via all the possible methods.</p>

        </Tab.Pane>

		<Tab.Pane eventKey="three">
   <h2>Betting rules</h2>
   <p><b>3.1</b> The betting company accepts bets on the outcome of sporting and other events with a deliberately unknown result, including the use of telecommunication networks.<br/>
   <b>3.2</b> Every time downloading the client’s application, regardless of the use of the media resource (via Internet or mobile services), by opening an account and using an existing account, betting or participating in any games, taking any prize, the client confirms his agreement with the current version of the Rules and the obligation to act in accordance with these Rules.Anytime a user downloads the client, registers an account, uses an existing account, bets on any games, takes any winnings, etc., this signifies the User’s agreement and obligation to act in accordance with the current rules.<br/>
   <b>3.3</b> Da Bomb Betting has the right to make any changes and additions to the rules set forth herein, the provisions and procedures of payments without an individual prior written notice. Users are notified about this with appropriate announcements. The conditions of previously staked (placed) bets remain unchanged, and bets which are accepted are subject to the posted rules at the time of acceptance.<br/>
   <b>3.4</b> Da Bomb Betting reserves the right to refuse service to any person without disclosing the reason(s).</p>
        </Tab.Pane>

		<Tab.Pane eventKey="four">
    <h2>Policy of site use</h2>
   <p><b>4.1</b> Da Bomb Betting does not bear any responsibility for any losses or damages claimed as resulting from the use of this site or from its content. This provision equally applies to the use or misuse of the content of the site by any person, inability to access the site or use it, to delay in functioning or transmission of data, failures in communication lines, any errors, misprints or omissions in the content of the site.<br/>
   <b>4.2</b> Loading photos and video from the website or its sub-sites is permitted only for private use. Publication, transmission or reproduction of this data for any other purpose is strictly prohibited.<br/>
   <b>4.3</b> Da Bomb Betting monitors traffic of its website actively, and reserves the right to block access in cases of suspected automated betting (bots).<br/>
</p>
        </Tab.Pane>
		<Tab.Pane eventKey="five">
     <h2>Glossary</h2>
   <p><b>5.1</b> Basic terms accepted in this edition of the rules:
“Single” – a bet on a single event;
“Express” – this is a bet on the outcome of several different events simultaneously. When you place a bet on the outcome of two or more events (for example, two different football matches), you can unite them in “Express”. To win an express bet it is necessary for you to not have any failed predictions (losses) on any of the outcomes which are included in the Express. Loss on one of the outcomes of an express bet means loss throughout the entire bet. Winnings of the express bets is equal the product of the bet amount at the oddss of played outcomes included in the express (if on one of the bets included in the express occurs a return, then the odds on it equals to 1).
<br/><br/>
“System” – a set of “express bets” of a particular set of events. Each combination of the system is calculated as a separate express bet. To win in the system it is sufficient that the indicated number of predictions of the total number be correct (it is defined by name, that is, for example, in the system “3 of 4′′ it is necessary that 3 of the 4 predictions prove correct, for “5 of 7′′ it is necessary properly to predict the outcome of the five events of 7 options, etc.). It must be taken into account that because of the fact that only a part of the total bet amount is specified on each of the express of the system bet (proportional to the number of options) then the winnings by such a system bet will be less than the possible general winnings by the express bet. In some cases, when not all outcomes are predicted, the amount of winnings may be less than the amount of the bet, but in contrast to express bets, a part of the amount will be offset on account of correctly predicted outcomes.
<br/>
“Chain” – bet which is a combination of “single” bets including events independent from each other. The sum of single bet of chain‘s each event is equal to the sum of chain. The client is given a chance to determine independently the order of the bets included in the chain and stake only on the first event of the chain. Thus the concept of “chain account” is imported. After the tournament of each single bet included in the chain the sum of that account is calculated. Initially it is equal to the sum of the first bet. If the sum on the chain account is less than its initial sum, the chain’s next event’s single bet is calculated by the account balance. The procedure of chain’s calculation depends on the order of bets mentioned in the given ticket but not on time. The sum which remains on the account after calculation of all bets in the chain is a subject to payment. If the sum on the chain account reaches zero – the chain breaks and is considered to be lost.
<br/>
“Handicap” – the advantage or the leeway of the entrant of competition, expressed in goals (points/) sets, etc., which is provided by the bookmaker office to the entrant with bets on it. The result of the event subject to the handicap is determined in this way: the handicap given to the entrant is added to the corresponding result shown by the entrant in the competition. If the results thus obtained are in favor of the elected entrant, he is considered as a winner, and the bets on his win subject to the handicap win. Payments are carried out with the odds of winnings indicated in the description of bets. If the result is in favor of the opponent – the bets are lost. If the result subject to the handicap is a draw, then the odds of winnings under such a result will be equal to 1 (return of the bet);
<br/>
“Total” – the number of goals, pucks, points or the games, scored (thrown, gained or played) by an entrant or entrants of events.
<br/></p>
        </Tab.Pane>
		<Tab.Pane eventKey="six">
    <h2>Policy of Bets</h2>
   <p><b>6.1</b> The values of the parameters (including handicaps, totals) and oddss of the events specified in the line of the current day may change after any bet, but conditions before bets are the same, except in cases of technical and obvious mistakes in the line.<br/>
   <b>6.2</b> As an amount of bet only the amount confirmed and registered by the company is accepted.<br/>
   <b>6.3</b> The User is the only person who is responsible for the bet. Once a bet is made and its acceptance is confirmed, it cannot be changed or canceled by the User. Da Bomb Betting does not provide any guarantees with respect to the “lost” or “double” bets made by customers. Please carefully check all the bets you have made.<br/>
   <b>6.4</b> Loss of your password cannot be a reason for the withdrawal rates or cancellation of the request for payment of winnings.<br/>
   <b>6.5</b> A bet is accepted as soon as it is confirmed by the company. All accepted bets are displayed in the “Bet History”. In some cases there may be a delay until a bet is listed in your history. In case of any dispute the time when the bet was registered in the system of the company is considered to be its true submission time. If the User did not receive any notification about the acceptance of bet, the rate is still considered to be accepted if it is displayed in the “Bet History.”<br/>
   <b>6.6</b> Failures of communication or other technical failures in customer communications are not a reason for the cancellation of a bet if the bet has been registered in the server.<br/>
   </p>
        </Tab.Pane>
		<Tab.Pane eventKey="seven">
    <h2>Displaying Data</h2>
   <p><b>7.1</b> The Company is not responsible for the accuracy, completeness and timeliness of the proposed information services, including data on the events of Live and mailing services.<br/>
   <b>7.2</b> Da Bomb Betting makes every effort to make the information on Live events (such as results, the time of a game and the statistics of teams /players) be correct. However, this information is displayed only for informational purposes. Da Bomb Betting is not responsible in case of any errors in this data.<br/>
   <b>7.3</b> Da Bomb Betting is not responsible and does not accept any claims regarding the accuracy of the translation from foreign languages of team names and the names of the players.<br/>
   <b>7.4</b> If in the indicated event/match more than one member with the same surname is present and during bet acceptance the full name of the athlete or other features identifying them uniquely have not been indicated, all bets are refundable.<br/>
   <b>7.5</b> If in the name of the match or in the name of the position one or more entrants are indicated in the line incorrectly (except for errors in translation), that is if the other team/entrant of represented tournament is indicated instead of the necessary one, all the bets on that event are recognized as invalid and a return is made. Only Da Bomb Betting has the right to determine the events belonging to this category.<br/>
   <b>7.6</b> In cases when the specific details of the team such as a particular age group (for example 18 years/to 21 years/youth teams), gender (women) or the status of the team (reserve) are not indicated in the line resulting in the marking of the other team in the line instead of the declared one, all bets on this match are refundable. In other cases when incomplete name or possible grammar errors are indicated in the line, all bets remain in force. If the gender of teams is not indicated in the line, it is considered that the gender of teams is male. In case women’s teams are participating in a match it is necessary to have a detailed indication about it in the line otherwise bets on this match are a subject to return.<br/>
   <b>7.7</b> Date and time indicated in the line are the date and time of discontinuation of betsˈ acceptance on the event (except for Live events) and do not necessarily coincide with the date and time of the actual start of the event. The actual time of the event while counting the bets is considered as the time of the start of the event, which is determined based on the official documents of the organization conducting the competition, as well as the data of Da Bomb Betting .<br/>
   <b>7.8</b> If the place of conducting a meeting is not indicated in the line, in the list of events the host of the field is indicated firstly, except for competitions of American types of sports. All the information contained in the “cap” to a particular sport, carries an ancillary character. Possible errors in the given information are not a ground for the return of bets.<br/>
   <b>7.9</b> On acceptance of bets for Live Events there is a set delay, the value of which may vary depending on the sport, market, and other features, and it is determined exclusively by Da Bomb Betting for security and protection from subsequent bets.<br/>
   <b>7.10</b> For clients convenience, taking into account the specificity of Live Events in which oddss change very often a client can choose one of the following options: to “Accept all changes in the oddss”, “Accept only marked-up bets” or “Do not accept bets if the oddss change,” which determines respectively whether the bet is accepted if during the time of its arrangement the odds changed, at any changes of it, or just in the case when the changed bet exceeds the estimated odds of the bet of the client.<br/>
   <b>7.11</b> Incorrectly indicated date of the conducted match is not a ground for cancellation of the bet.<br/>
   <b>7.12</b> If the match began from the moment at which it was previously interrupted, and in the line that was not stipulated, the bets are refundable.<br/>
   </p>
        </Tab.Pane>
		<Tab.Pane eventKey="eight">
    <h2>Interlocking events and outcomes</h2>
   <p><b>8.1</b> In “express” and “system” bets it is forbidden to include different events that relate to the same match, not even the ones which are interdependent directly. If in such bets were included any events that relate to the given match, this bet is refundable, even if the computer program of accepting the bettings did not block this bet during its submission. Da Bomb Betting reserves the exclusive right to determine whether the events are connected.<br/>
   <b>8.2</b> In the game on output-absence from the group in “express” and “system” bets it is allowed only to bet on one team from the given group. If in such bets qualify-not qualify of the teams from the same group were included then the given bets are refundable, even if the computer program accepting the bets did not block this bet during its submission. Da Bomb Betting reserves the unique right to decide whether to accept bets on the position of qualify or not qualify in “express”, “system” and in “single” bets.<br/>
  
   </p>
        </Tab.Pane>
		<Tab.Pane eventKey="nine">
    <h2>Obvious and technical errors</h2>
   <p><b>9.1</b> The Company is not responsible for errors in typing, transfer or counting of bets. In particular, Da Bomb Betting reserves the right to correct obvious errors made while entering information on betting oddss and/or calculation of the amounts at the bets (for example, errors in the oddss indicated in teams incorrectly, incorrectly indicated events, etc.), or declare invalid the corresponding bets even after completion of the event. In the cases of correcting obvious errors bets are returned. The most common types of technical errors are the following:<br/>
   <li>Errors of input of odds – when the odds (or the setting of the handicap/total, etc.) is displayed without a separator (dot) or with any additional number.
Example: instead of the odd 1.8, 18 is displayed, instead of 2, 23 is displayed, instead of 3, 30 is displayed etc.
</li>
<li>“Mirror Errors” – when the oddss for players are displayed in a diametrically opposite manner. In this case, the favorite of the game (including current favorite of live event) is displayed by a odds exceeding the odds of the outsider of the given event.
Example: Basketball. Barcelona-Unicaja. The winner of the match. Instead of 1.2 and 4, 4and 1.2 is displayed.
</li>
<li>Writing errors – if all the oddss are indicated correctly, however, teams are written in an incorrect order (usually occurs for events taking place on a neutral field).
Meaning for the previous example: record: Basketball. Unicaja-Barcelona. The winner of the match. 1.2 and 4 instead of Basketball. Barcelona-Unicaja. The winner of the match. 1,2 and 4 
Clear indicators of obvious errors are: very large difference in the oddss of Da Bomb Betting and other bookmakers, the oddss that are nearly impossible to give in the current situation of the match.
The right to determine whether or not an obvious or a technical error was made in any case belongs exclusively to Da Bomb Betting.

</li>
   <b>9.2</b> Da Bomb Betting reserves the right to abolish the bets made on deliberately incorrect oddss and obvious errors. The customer will be informed about changes in the balance immediately.<br/>
   <b>9.3</b> Da Bomb Betting reserves the right to cancel the bets even after the end of the event if these bets are won by the User as a result of a technical failure or an obvious error.<br/>
   <b>9.4</b> In cases when due to technical reasons the User’s bets were not provided with the necessary amount of funds in the account, the bets are considered as invalid.<br/>
   <b>9.5</b> In case of mistakenly having credited the means to the customer’s account the latter shall immediately notify Da Bomb Betting about the mistake. If due to an error a negative balance has been formed at the client, the betting company has the right to cancel the uncalculated bets despite the fact that they were made before the error occurred.<br/>
   <b>9.6</b> The Company reserves the right to oblige the User to liability for any damage caused to it if the user used a technical or administrative error intentionally when making a deposit and/or during the receipt of money on the account.<br/>
   </p>
        </Tab.Pane>

		<Tab.Pane eventKey="ten">
    <h2>Bets on credit</h2>
   <p><b>10.1</b> Da Bomb Betting does not accept any bets or provide services to customers on credit. All bets must be confirmed with funds existing in the account of the User.<br/>
   </p>
        </Tab.Pane>

		<Tab.Pane eventKey="eleven">
    <h2>Legal Aspects</h2>
   <p><b>11.1</b> Da Bomb Betting states explicitly that bet placement and betting organization, as well as participation in sports betting, multiplayer poker, game in casinos and games like lottery may be restricted by law or even prohibited in some countries. Such restrictions or prohibitions may be imposed even if the company obtained the necessary permission (license for betting or organizing gaming) to place bets and betting organizations.
<br/>
The User should note that if the placement of bets or participation in sports betting, multiplayer poker and games such as lotteries is prohibited or permitted only under certain conditions, which are not followed, the responsibility for any damage caused by this rests solely with the User.
<br/>
The client must also take note that the company is not required to provide clients with information, instructions and warnings in a wider range than in the present paragraph. In particular, the company is not liable for damages suffered by the User due to the violation of the relevant legislative prohibitions functioning in his/her country.
<br/>
   <b>11.2</b> You should register on one of the websites which belong to the group of Da Bomb Betting companies according to your country of residence. By rating or participating in the games, the User confirms that he has reached the lowest age of consent limited by law of his country, and confirms his/her capacity to enter into the agreement with the company. In case of non-fulfillment of these requirements the User’s account will be closed and necessary measures will be applied.<br/>
   <b>11.3</b> The User agrees that his personal information provided to the company during the pool betting, games in multiplayer poker, casino games and games such as lotteries, as well as in any other game is stored and used by automatic means.<br/>
   <b>11.4</b> You have registered on websites which belong to the group of Da Bomb Betting companies more than once and/or you have more than one account. In this case, the Company has the right to block all the accounts at its discretion and to leave only one account corresponding to the country of residence.By registering the User confirms the exclusive intellectual property of the company’s regulations, as well as the system controlled by them.<br/>
   <b>11.5</b> The Company reserves the right to inform Users about special promotions and new products by telephone and e-mail.<br/>
   <b>11.6</b> Legal relations between the User and the company are a subject to laws of Curacao and are regulated by it with the exception of the appropriate legal rules of international private law. The place of performance of all obligations at betting and gaming, as well as at the appropriate bets, is Curacao. All disputes arising over or regarding bets made by customers, are resolved if it does not contradict the law, by the court in Curacao, having the relevant territorial and subject matter jurisdiction.<br/>
   <b>11.7</b> The Company expressly states that it does not provide users with any advice (consultation) on tax matters and/or legal matters.<br/>
   <b>11.8</b> No disputes about the bets can lead to a lawsuit, legal action, as well as claims on bookmaker licenses if the company complies with the provisions of arbitration.<br/>
   <b>11.9</b> In case of any claims and disputes from Users’ side and people representing their parties, the company’s liabilities are limited by the size of bets or its possible winnings<br/>
   <b>11.10</b> In case of any disputes or claims the database of Da Bomb Betting has priority over any other data.<br/>
   <b>11.11</b> If there is discrepancy between the English version of these rules and the translation into another language, the English version is considered to be the correct one.<br/>
   <b>11.12</b> Any matters of dispute including claims about the results of the event are accepted to be reviewed by the bookmaker office within 5 days from the end of the sporting event.<br/>
   <b>11.13</b> Da Bomb Betting does not hold Users’ funds in segregated or separately protected bank accounts. It is important for the User to understand that deposits of Da Bomb Betting are not in banks and no interest is accrued upon them.<br/>
   </p>
        </Tab.Pane>

		<Tab.Pane eventKey="twelve">
    <h2>Restrictions on rates</h2>
   <p><b>12.1</b> Bookmaker company has the right not to accept bets from any person without giving any reason and reserves the right to close or temporarily block the accounts of individual Users without prior notice.<br/>
   <b>12.2</b> Minimum and maximum size of bets for all sporting events is defined by the bookmaker office and is a subject to changes without prior written notice. In addition, Da Bomb Betting reserves the right to impose special restrictions on the accounts of individual Users.<br/>
   <b>12.3</b> Da Bomb Betting does not recommend two or more Users to place bets from one IP-address (from the same computer or the same local area network) in order to avoid suspicion of collusion (cheating). In such cases it is necessary to reconcile the possibility of the game with Da Bomb Betting by explaining the circumstances. Da Bomb Betting has the right to block the account of such a User and ask them to send the documents verifying their registered identity.<br/>
   <b>12.4</b> Winnings are paid only within the limits set with maximum limits of the company. If the User has made a bet and the winning exceeds the maximum, the company will not pay any amount of money exceeding the maximum limit.<br/>
   <b>12.5</b> Results for sports statistics are published on the official website of Da Bomb Betting within 3 days after the end of a sporting event.<br/>
   </p>
        </Tab.Pane>

		<Tab.Pane eventKey="thiteen">
    <h2>Late bets</h2>
   <p><b>13.1</b> Bets placed after the start of the event, except the bets for Live Events, in all cases, are invalid and are returnable, and are excluded from the “express” and “system” bets. Any bet (including bets for Live Events) made on an event, the result of which is already known by the time of betting, is considered invalid and will be refunded.<br/>
   <b>13.2</b> If the User wishes to challenge the time of the start of the event, he must provide an official document (or a link to the website of regulatory bodies of the given event) which confirms the actual time of the start of the match on which the given bet was made.<br/>
   <b>13.3</b> While arising disputes regarding the time of the start of the event, if the official regulatory bodies of the appropriate tournament/event do not provide (or lack) such information, the information of Da Bomb Betting about the start time of the event is considered the only correct one while calculating the bets.<br/>
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="fourteen">
    <h2>Return</h2>
   <p><b>14.1</b> If as a result of the match the total number of scored goals is equal to the total of the bet and among the proposed outcomes of the given total bets there has not been the option equal ... (the indicated number) of goals, a return is made. Similarly, for the individual totals, totals of the player, totals of cards/corners /removals, fouls and other parameters if the final value of the match and the proposed parameter of the total are equal a return is made.<br/>
   </p>
        </Tab.Pane>
        <Tab.Pane eventKey="fifteen">
    <h2>Privacy of User’s data</h2>
   <p><b>15.1</b> The User is responsible for maintaining the confidentiality of any information related to their account, and shall take all measures to prevent the use of personal accounts by unauthorized persons. Any operations confirmed by the username and password of the account holder shall be valid and have legal force.<br/>
   <b>15.2</b> If the User suspects that his username and password are known to a third person, at any time they have the right to send a request to Da Bomb Betting to block the account temporarily for all circumstances to be made clear.<br/>
   <b>15.3</b> To ensure the security of personal data of Users, Da Bomb Betting communicates with Users via the e-mail indicated by the User during registration.<br/>
   <b>15.4</b> The User is responsible for maintaining the confidentiality of any information related to their e- mail, and shall take all measures to prevent the use of e-mail address by third parties. Da Bomb Betting is not responsible for any loss or damage resulting from the connection between the bookmaker office and the User using a registered e-mail address.<br/>
   </p>
        </Tab.Pane>

    
        <Tab.Pane eventKey="sixteen">
    <h2>Match-fixing</h2>
   <p><b>16.1</b> In case of possible interference in the match result and the formation of the oddss for the match (including cases of suspicion in match-fixing), Da Bomb Betting reserves the right to suspend the payments on this event or to cancel the bets on this match, as well as hand over the information on relevant side to the appropriate authorities for further investigation and prevention of similar incidents.<br/>
   <b>16.2</b> Da Bomb Betting reserves the right to suspend the payment of the rates and make a return on the rates to the event, if there are solid grounds to believe this match was negotiable. Such grounds may be based on the size, number and nature of the bets made by one or all of the possible methods of betting.
The decision of the governing bodies of relevant federations and regulatory bodies are also taken into account. In such cases Da Bomb Betting reserves the right to hand over the information on related party to the governing bodies for further investigation and preventing similar incidents.
<br/>
   
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="seventeen">
    <h2>Neutral field</h2>
   <p><b>17.1</b> In cases when the match is transferred to a neutral field (NF) rates persist. They are refundable in cases when the match is transferred to the opponent’s field. In case of the play when the teams are from the same city and are transferred to another field, bets are valid and are not refundable. In international matches, the refund of bets is made only when the match transfers to the field of an opponent state.<br/>
   </p>
        </Tab.Pane>


        <Tab.Pane eventKey="eighteen">
    <h2>Privacy of User’s data</h2>
   <p><b>18.1</b> An event is considered interrupted if there was no play for an indispensable amount of playing time specified according the rules of the company, even if the result at which the event was interrupted is later confirmed to be final.<br/>
   <b>18.2</b> The matches consider to be taken place, if they have been interrupted, not finished in the time- frame specified in the points 6 and 7 and if the played time is the following:<br/>
- Soccer - 90 min.<br/>
- Futsal (duration 2 x 20 minutes.) - 35 minutes<br/>
- Futsal (duration 2 x 25 minutes.) - 45 minutes<br/>
- Basketball NBA (National Basketball Association and PBA, CBA) - 43 min.<br/>
- Eurobasketball – 35min.<br/>
- Hockey NHL (National Hockey League) - 55 min.<br/>
- Bandy - 60 min<br/>
- Eurohockey - 50 min.<br/>
- American football - 55 min.<br/>
- Baseball - 5 periods (innings)<br/>
- Handball - 50 min<br/>
<br/>
   <b>18.3</b> For interrupted events recognized as cancelled, if not otherwise stipulated in the rules on sports, all bets are refundable, except for ones for which the result is exactly defined at the time of the stop of the event. For interrupted events bets on the relative performance time (periods, etc.) are a subject to unconditional return.

<br/>
   </p>
        </Tab.Pane>


        <Tab.Pane eventKey="nineteen">
    <h2>Events transfer</h2>
   <p><b>19.1</b> If the start time of the event has been changed and otherwise is not stipulated in the rules of sports, all bets on the event are refundable.<br/>
   <b>19.2</b> During a game on the passage to the next round, in cases of transferring the game period onto subsequent days, or changes of the field, the bets remain valid. If the “passage” of the team which goes to the next round later has been canceled because of any reason, the cancellation is disregarded, and the payment is made according the results of played matches. If the second match did not take place or was interrupted, then all bets on it, including the passage, are refundable.<br/>
Payments on the passage are made only at bets made before the first match. If none of the matches has taken place, the bets will be refunded.
<br/>
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="twenty">
    <h2>Calculation of bets</h2>
   <p><b>20.1</b> Da Bomb Betting validates the results on the basis of official reports and alternative sources of information (including videos of the event) after finishing the event. Calculation of bets is based on the results published on the website www.DaBombBetting.com in the section “Results”. In case of discrepancy of results from different sources of information, as well as in the case of their obvious mistakes the final decision to determine the results belongs to Da Bomb Betting .<br/>
   <b>20.2</b> All inquiries of Users regarding to the correctness of the results of Da Bomb Betting site should be based only on the basis of official sources of information (websites of regulatory bodies for these championships). Information from other sources cannot be considered a basis for the reviewing/reappraisal of bets.<br/>
   <b>20.3</b> Valid (factual) results considered to be the results, which are announced on the basis of official protocols or other sources of information, right after the end of the event.The organizer uses the following official sources:<br/>
<li>www.uefa.com</li>


<li>www.fifa.com </li>

<li>www.beto.gr </li>

<li>www.bundesliga.at</li>

<li>www.turkish-soccer.com</li>

<li>www.rfpl.org </li>

<li>www.premierleague.com </li>

<li>www.sportinglife.com </li>

<li>www.gazzetta.it </li>

<li>www.bundesliga.de </li>

<li>www.laliga.es</li>

<li>www.lfp.fr </li>

<li>www.mlssoccer.com </li>

<li>www.nfl.com</li>
 
<li>www.russiabasket.ru </li>

<li>www.nba.com </li>

<li>www.euroleague.net</li>

<li>www.nhl.com </li>

<li>www.fhr.ru </li>

<li>www.rusbandy.ru</li>

<li>www.atptennis.com </li>

<li>www.wtatour.com</li>

<li>www.itftennis.com</li>

<li>www.daviscup.com</li>

<li>www.fedcup.com</li>

<li>www.ausopen.com</li>

<li>www.rolandgarros.com</li>

<li>www.wimbledon.com</li>

<li>www.usopen.org</li>

<li>www.mlb.com</li>

<li>www.pgatour.com</li>

<li>www.iccworldtwenty20.com</li>

<li>www.fide.com</li>

<li>www.volley.ru</li>

<li>www.nyse.com</li>

<li>www.nasdaq.com</li>


<p>The organizer can use other official sources when there is need. In exceptional cases, the organizer reserves the right to use video viewing to adjudge the true result.</p>
   <b>20.4</b> Live event bet results are calculated based on what is known immediately after the end of the event/competition. All following changes (for example, changes by decision of a jury after the game) are not taken into account while deciding the bets. The company itself calculates these bets based on its statistical data about the actual course of the game. If not otherwise indicated, all bets on non- participating athletes in the competition will be void.<br/>
   <b>20.5</b> In cases when the company’s statistics (based on TV coverage) is different from the official statistics, the bets are calculated according to the company’s statistics.<br/>
   <b>20.6</b> If more than one participant or team is declared as a winner of the competition, the odds of winnings is divided by the number of winners (the odds can’t be less than 1)<br/>
   <b>20.7</b> All operations on playing accounts are made in the currency chosen by the User during registration.<br/>
   <b>20.8</b> All bets for which the outcomes are already known (in particular bets on who will score the next goal or win a certain segment of the match), are considered valid and are calculated even if the event or tournament was suspended.<br/>
   <b>20.9</b> If a match is not completed and is considered as incomplete, then all bets on it are refundable, (except what is mentioned in point 16.1) even if the result, at which the event was suspended, later is confirmed as final.<br/>
   <b>20.10</b> If it does not contradict the special rules of individual sports, the result announced on the day of the match is taken for the result of the match. Any possible changes in the result, conditioned by the revision of the results of the event by governing bodies and disciplinary penalties of teams, for the purpose of betting, are not taken into account, and the initial results of the calculation of bets remain in force.<br/>
   <b>20.11</b> In our system, the deposit limit logic works with fixed calendar day hours with (GMT+4) time zone. Meaning that the user will be able to deposit a maximum amount of his limit during 24 hours from 12 am (GMT+4) till 12 am (GMT+4) of the next day.<br/>
   
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="twentyone">
    <h2>Betting groups/syndicates and Match/Event limit</h2>
   <p><b>21.1</b> Users are required to bet only on their own behalf. Recurring bets containing the same selections from one or more clients can be recognized as invalid and get canceled by the company. Even after the official outcome of such bets is known, in cases when the company is confident that Users have acted in collusion or as a syndicate, or suspicious bets have been made by one or more Users in a short period of time, such bets may be considered invalid and get canceled by the company.<br/>
   <b>21.2</b> Bet limits by sport events are set accordingly to the player’s account status and individual bet/event. If the player makes an attempt to place a bet outside the limits specified for an event, a warning message ” Max. Stake – Limit Reached / Official limit exception” will be displayed in the bet slip as a notification. Multiple bets which combine same selection within the same bet event are not accepted. Avoiding the Warning Message and Non-compliance with this rule will result with cancelation of all placed bets and a refund of the initial stake.
<br/>
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="twentytwo">
    <h2>Non-participation in the event</h2>
   <p><b>22.1</b> If it does not contradict the special rules of individual sports, when an event participant for whatever reason cannot take part in the event which was bet on, the bet is considered valid and lost by the client. The exceptions are the following cases:<br/>
1. The event/tournament is canceled;<br/>
2. The event /tournament is considered invalid;<br/>
3. The location of the event was changed;<br/>
4. The event/tournament is suspended.<br/>
<br/>
   <b>22.2</b> In case of bets “Who is taller” meaning bets with two outcomes, if one of the participants refuses to take part before the event/tournament, the bet is refundable.<br/>
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="twentythree">
    <h2>Rules on sports</h2>
   <p><b>23.1</b> Sport rules take precedence over the general rules.<br/>
   <b>23.2</b> Some types of bets<br/>
   <b>23.3</b> In case of betting on the “Odd number/Even number of Total”, regardless of the sports and the proposed position (including total goals/cards/fouls etc.) if the result is “0′′, the bets are calculated as “Even number”<br/>
   <b>23.4</b> All bets on the number of medals are calculated according to the official medal table at the end of the event. Any changes undertaken by the governing bodies later are not taken into account.<br/>
   <b>23.5</b> Any medals won by the country team in any competitions shall be considered as one regardless of the number of participants in this team.<br/>
   <b>23.6</b> In case if any market has the potential outcome “Any other”, this outcome includes all participants of the event, not listed in the options of that market separately.<br/>
   <b>23.7</b> In case of game on any event in the “additional markets” of tours (total number of goals, wins, losses, draws, angular, yellow cards etc.), only those matches of the tour are taken into account which are included in the daily line.<br/>
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="twentyfour">
    <h2>Registration</h2>
   <p><b>24.1</b> Only registered Users can participate in bonus-programs of the company. To register, fill in the registration form by providing your first name, last name and email. After receiving a verification email, follow the instructions to verify your account to be able to make deposits and place bets.<br/>
   <b>24.2</b> Registration and betting is allowed exclusively to people over 18 years old (or the allowed age range in the given country from which the user has opened a deposit and made bets). Users have complete responsibility as to dealing with the legality of Internet gambling in the region where they live and/or for providing the appropriate authorities of their country with information on winnings. The Company reserves the right to require proof of age and block the User’s account before receiving the appropriate documentation.<br/>
   <b>24.3</b> All information provided during the registration must be accurate and complete. In particular, when using credit or debit cards, the cardholder’s name and surname must match the name and surname indicated in the registration form, otherwise the account will be blocked. All bets made before locking the account are recognized as valid.<br/>
   <b>24.4</b> The Company reserves the right to block the accounts of Users who have reported false data, as well as to deny them the payment of any winnings. At the request of the company the User must present an official document with a photograph, identifying his identity (a passport copy), and also proof of authenticity of the indicated address data and telephone.<br/>
   <b>24.5</b> Each User can have only one account. Registered Users cannot re-register as a new client with a new name or a new email address. In case of violation of this rule, the company has the right to void all bets made by the User.<br/>
   <b>24.6</b> The User has no right to permit any third party use their game account.<br/>
   <b>24.7</b> Please note that you should not send us the details about your credit card account or other sensitive financial information via an unencrypted e-mail.<br/>
   <b>24.8</b> Da Bomb Betting allows all its Users to choose their own combination of username and password. Users should keep such information in secret. If your username and password are entered correctly while accessing the site, all bets remain in force and cannot be canceled or changed by the User. If you suspect that someone other than you knows your password, change it immediately on our website. If you have forgotten the password or a part of it, please send a request to Live Support for a new password.<br/>
   <b>24.9</b> Users who place their bets in Da Bomb Betting via a cell phone should remember that Da Bomb Betting is not responsible for any loss of data in the mobile phone of the client, and is not responsible for any commission of mobile and internet operators. By registering, the client confirms that he accepts and agrees to these Terms.<br/>
   </p>
        </Tab.Pane>


        <Tab.Pane eventKey="twentyfive">
    <h2>Currency</h2>
   <p><b>25.1</b> Currently Users have the right to place bets in the following currencies: EUR and USD. Da Bomb Betting reserves the right to block the reception of bets and operating activities in any of the indicated currencies. In this case, all the necessary payments on the accounts of blocked currency would be held in another currency equivalent at the interbank exchange rate for that day.<br/>
   </p>
        </Tab.Pane>


        <Tab.Pane eventKey="twentysix">
    <h2>The bonus program</h2>
   <p><b>26.1</b> All bonuses of Users are limited individually to one person, home address, telephone number and e-mail address, one bill of payments (such as by card number or Skrill account), as well as the computer being used (including the institute, Internet club and other public institutions). The company has the right to refuse the bonus to any User or group of User. Bonus programs are available only to Users who have made a deposit in real currency to their account in Da Bomb Betting .<br/>
   <b>26.2</b> In case of violation of any requirement of the bonus programs, and also if there is any evidence of recurrent bets at one and the same event from a group of customers, or conspiracy, regardless of the result of the given bets, Da Bomb Betting reserves the right to deprive these Users from a bonus and to consider the corresponding bets as invalid. For the purposes of protection against fraud the company has the right to demand a document from the client proving identity before transferring a bonus.<br/>
   </p>
        </Tab.Pane>

        <Tab.Pane eventKey="twentyseven">
    <h2>Deposits</h2>
   <p><b>27.1</b> Available methods of payments are determined by the country and the currency selected during registration. A complete list of fees, limits on them and other items is displayed on the Deposits and Withdrawals page. Da Bomb Betting reserves the right to change these terms and details.<br/>
   <b>27.2</b> When conducting any financial transactions it is necessary that the name of the owner of the debit/credit card or bank account exactly match the name of the owner of the appropriate account of Da Bomb Betting . Otherwise Da Bomb Betting reserves the right to cancel all transactions and make a return on all bets made while using someone else’s account or credit/debit card.<br/>
   </p>
        </Tab.Pane>


        <Tab.Pane eventKey="twentyeight">
    <h2>Entry of money on account</h2>
   <p><b>28.1</b> If any funds have been transferred to the User erroneously, the User is obliged to notify the company immediately. Any winnings of the client arising from such an error shall be considered invalid, and such bets are refundable, regardless of the delay between the origin of the error and the time it was seen.<br/>
   <b>28.2</b> If the deposits to the account were made for any other purpose than bets, poker, casino and financial betting, the company (particularly in case of suspected fraudulent money turnover) reserves the right to cancel a deposit and collect from the User all costs incurred as a result of processing the deposit.<br/>
   <b>28.3</b> If the User’s deposit exceeds the bet’s amount, at the client’s request for withdrawal Da Bomb Betting reserves the right to charge the User all costs incurred as a result of processing deposits and withdrawals.<br/>
   </p>
        </Tab.Pane>


        <Tab.Pane eventKey="twentynine">
    <h2>Financial Limitations</h2>
   <p><b>29.1</b> The maximum winning of one ticket is 50 000 USD. If the bet comes with a bonus and thereby the winning sum exceeds 50 000 USD, the bonus is not paid. -The maximum odds of a bet is 1000. In case it exceeds 1000, the winning is calculated with 1000 odds.<br/>
   <b>29.2</b> The minimum single bet on any event is €0.5 or equivalent amount in players account currency. The minimum bet in express or/and system bet is €0.5 or equivalent amount in players account currency. Unless otherwise stated, only one bet per sport event or match is allowed. Maximum stake per bet must be equal or smaller than the last deposit amount.<br/>
   <b>29.3</b> The maximum odds of express bets is 1000. Express bets, in which the odds of winnings exceeds the maximum, are calculated with a odds of 1000. In these cases, the sum of winnings should not exceed the maximum payment for a single bet. The maximum odds of winnings for each version of the system is 1000. Variants of systems on which the odds of winnings exceeds the maximum, are calculated with odds 1000.<br/>
   <b>29.4</b> The maximum amount of the bet on the event depends on the sport and the events and is defined by the bookmaker network specifically for each event and each type of bet and is subject to change without prior written notice. Da Bomb Betting reserves the right to limit the maximum bet on individual events, as well as the introduction and removal of specific restrictions on the accounts of individual Users without notice or explanation of reasons.<br/>
   <b>29.5</b> All financial limits apply to any individual customer, or/and syndicates group of players acting as a collective unit, who have placed two or more bets containing the same match selections, including where placed in a series of bets, at a different range of odds markets, over a number of days using various players’ accounts and/or across different betting channels. Should we identify that two or more bets have been placed this way, all those bets will be considered as void and only first betting slip submitted will be considered as a legitimate bet.<br/>
   </p>
        </Tab.Pane>



        <Tab.Pane eventKey="thirty">
    <h2>Payments / Withdrawals</h2>
   <p><b>30.1</b> Payments are processed in a time not exceeding 72 hours starting from the moment that the request was made. Before the first payment will be made to the User by electronic payment methods (Skrill, credit or debit card, etc.), the client is obliged to attach an electronic copy of a passport to the Documents section to confirm their identity. Remember that forgery is severely punished by law, and in cases of suspicion of placing a counterfeit or an edited copy of the documents by electronic methods, Da Bomb Betting reserves the right to send such documents to the appropriate regulatory authorities.<br/>
   <b>30.2</b> Before making the payment, the employees of the company will verify the correspondence of the name, surname, father’s name, birth dates of the client and also other data. If differences are found between the actual data and the data provided by the client, Da Bomb Betting reserves the right to make a refund for all bets by the User and refuse to pay out winnings to the User unless they prove the identity and accuracy of entered data.<br/>
   <b>30.3</b> If it turns out that the User has opened several accounts in the company, Da Bomb Betting reserves the right to refuse to pay out for these accounts (except the User’s assets legitimately transferred to the account of Da Bomb Betting after his payment of a fine in the measure of 10% of the total amount of deposits).<br/>
   <b>30.4</b> In the first order for a withdrawal in cash the User must enter valid passport details exactly as in the passport in the language of the country that issued the passport (or in the case of foreign passports – in English).<br/>
   <b>30.5</b> Group and family members should regulate personal relationships with each other – payments are made ONLY in the name of the owner of the appropriate account.<br/>
   <b>30.6</b> The User agrees to provide Da Bomb Betting with information about his bank account from which the bets will be made in particular, in order to transfer his winnings.<br/>
   <b>30.7</b> Da Bomb Betting is not responsible for changes in the amount of payments related to fluctuations of currency (rate of exchange).<br/>
   <b>30.8</b> If the User has requested a withdrawal in the amount of 1,000 Euros or more (or the equivalent in another currency at the interbank rate), Da Bomb Betting pays a commission on the transfer and subsequent operations of withdrawals in the given calendar month. Otherwise, the commission is paid to the bank by the User.<br/>
   <b>30.9</b> The Company reserves the right of funds’ withdrawal using a priority for itself method of payment for winning players (including credit/debit card or to the player’s bank account).<br/>
   <b>30.10</b> Before withdrawing the first payment, the player should bet 50% of their deposit amount. Starting from the second withdrawing the player should bet 100% of their deposit amount before being able to withdraw their payment. This requirement is for the purpose of reducing the risks of fraud and money laundering by players.<br/>
   </p>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
		
		
			</div>

            <Footer />
            </>
    )}
}



export default TermsAndConditions;
