import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import { Card,CardDeck,Button} from 'react-bootstrap';
import banner1 from "../components/images/banner_promo_1.png";
import banner2 from "../components/images/banner_promo_2.png";

class Promotions extends React.Component {

    constructor(props) {
        super(props)
		this.state = {
        }
    }


    componentDidMount() {
    }

    render() {
        return (
            <>
            <Navbar/>
            <Navbar_Bottom/>
            <div className="body_div" style={{color: "white",backgroundColor: "#1d2227"}}>
            <br/><br/>
            <CardDeck style={{width: "100%"}}>
  <Card>
    <Card.Img variant="top" src={banner1} />
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This is a wider card with supporting text below as a natural lead-in to
        additional content. This content is a little bit longer.
      </Card.Text>
    </Card.Body>

  </Card>
  <Card>
    <Card.Img variant="top" src={banner2}/>
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This card has supporting text below as a natural lead-in to additional
        content.{' '}
      </Card.Text>
    </Card.Body>

  </Card>
 
</CardDeck>
<br/><br/>
        
         </div>
        <Footer/>
            </>
            )}
        }
        
export default Promotions;
        