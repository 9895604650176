import React, { useState } from "react";
import f1 from './images/f_1.png'
import f2 from './images/f_2.png'
import f3 from './images/f_3.png'
import f4 from './images/f_4.png'
import f5 from './images/f_5.png'
import f6 from './images/f_6.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from 'react-router-dom'

import Carousel from "react-elastic-carousel";
import styled from "styled-components";

const Partner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  background-color: none;
  color: #fff;
  margin: 15px;
  font-size: 4em;
`;


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 3, itemsToScroll: 3 },
  { width: 768, itemsToShow: 4, itemsToScroll: 5  },
  { width: 1200, itemsToShow: 5 }
];


export default function Footer() {
  const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8,9,10]);
  const [pagination, setPagination] = useState(false);
  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(40);
    }
  };
  return (
    <div className="footer">
      {/* partner slider */}
        <Carousel  pagination={pagination} breakPoints={breakPoints} ref={carouselRef} onPrevStart={onPrevStart} onNextStart={onNextStart} disableArrowsOnEnd={false}  style={{backgroundColor: "#263238"}}>
          {items.map((item) => (
            <Partner key={item}>{item}</Partner>
          ))}
        </Carousel>
 


      <Row style={{ margin: "0" }}>
        <Col xs={12} sm={6}>
          {/* <h5>About Us</h5> */}
          <p className="footer_about">Betdrome has one of the largest selections of deposit and payout options in the industry, all of them 100% secure and reliable.
            <br />

            Bitcoin is the easiest way to deposit and get paid at Betdrome.
            <br />
            For help with a Bitcoin deposit, please check our Bitcoin Tutorial. For any other assistance, or if you have questions on the process, visit our Help Section or click the Live Chat button below to speak to our support team.
            <br />
            Please note that country restrictions may apply and the minimum/maximums for each option are subject to change without notice. Visit the Cashier for a personalized view of available options and limits.</p>
        </Col>
        {/* <Col xs lg={1}><img src={logo} alt='logo' className="footer_logo"/> </Col> */}
        <Col xs={12} sm={6}> 
          <div className='footer_partner_box'>
            <img src={f1} />
            <img src={f2} />
            <img src={f3} />
            <img src={f4} />
            <img src={f5} />
          </div>

        </Col>

      </Row>

      <Col xs={12} sm={12}>
        <ul className="list-inline footer-menu">
          <li className="list-inline-item"><NavLink to="/about_us">About Us</NavLink ></li>
          <li className="list-inline-item"><NavLink to="/terms_and_coditions">Terms And Conditions</NavLink ></li>
          <li className="list-inline-item"><NavLink to="/rules_and_regulations">Rules and Regulations</NavLink ></li>
          <li className="list-inline-item"><NavLink to="/privacy_policy">Privacy Policy</NavLink ></li>
          <li className="list-inline-item"><NavLink to="/contact_us">Contacts</NavLink ></li>
        </ul>
        </Col>

      <br/>
      <div><p className="footer_copyright">BETDROM.COM Betting production 2021©</p></div>

    </div>
  );
}
