import React, { useCallback, Component } from 'react'
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import { Container, Row, Col, Table, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { ModalPopUp } from '../components/Header/Buttons/ModalPopUp'


import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import { decode } from 'string-encode-decode'
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faArrowRight, faQuestionCircle, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import API from "../utils/PlatformAPI";
import Dropzone from "../components/Header/Buttons/Dropzone";



class MyProfile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            modalMessage: "",
            files:null,
            isVerified: false,
            first_name: "First Name",
            last_name: "Last Name",
            country: "Country",
            username: "Username",
            email: "Email",
            currency: "Currency",
            phone: "Phone",
            // new_username: "Username",
            // new_firstname: "Firstname",
            // new_lastname: "Lastname",
            // new_country: "Country",
            // new_email: "Email",
            // new_currency: "Currency",
            // new_phone: "Phone",
            new_password: "Password",
            new_password_repeat: "new_password_repeat",
            card_number: "1234 5678 9012 3456",
            holders_name: "NAME",
            expiration_date: "MM/YYYY",
            cvv_number: "111"
        }
        this.updateInputUsername = this.updateInputUsername.bind(this);
        this.updateInputFirstName = this.updateInputFirstName.bind(this);
        this.updateInputLastName = this.updateInputLastName.bind(this);
        this.updateInputEmail = this.updateInputEmail.bind(this);
        this.updateInputPhone = this.updateInputPhone.bind(this);
        this.updateInputCountry = this.updateInputCountry.bind(this);

        this.updateInputPassword = this.updateInputPassword.bind(this);
        this.updateInputPasswordRepeat = this.updateInputPasswordRepeat.bind(this);
        this.updateInputCurrency = this.updateInputCurrency.bind(this);

        // this.handleFile = this.handleFile.bind(this)
        // this.handleUpload = this.handleUpload.bind(this)
    }

    handleDrop = files => {
        // Push all the axios request promise into a single array
        const uploaders = files.map(file => {
          // Initial FormData
          const axios = require('axios')
          const formData = new FormData();
          formData.append("file", file);
          formData.append("Content-Disposition", "attachment; filename=file");
      
          // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
          return axios.post(API + "/upload_docs", formData, {
            headers: { "X-Requested-With": "XMLHttpRequest",filename : this.state.username + "_docs" },
          }).then(response => {
            const data = response.data;
            const fileURL = data.secure_url // You should store this URL for future references in your app
            console.log(data);
          })
        });
        const axios = require('axios')
        // Once all the files are uploaded 
        axios.all(uploaders).then(() => {
          // ... perform after upload is successful operation
        });
      }
      
    openModal = (e) => this.setState({ isOpen: true,modalMessage: e});
    closeModal = () => this.setState({ isOpen: false });

    updateInputUsername = (e) => {
        this.setState({ username: e.target.value })
    }
    updateInputFirstName = (e) => {
        this.setState({ first_name: e.target.value })
    }
    updateInputLastName = (e) => {
        this.setState({ last_name: e.target.value })
    }
    updateInputEmail = (e) => {
        this.setState({ email: e.target.value })
    }
    updateInputPhone = (e) => {
        this.setState({ phone: e.target.value })
    }
    updateInputCountry = (e) => {
        this.setState({ country: e.target.value })
    }

    updateInputPassword = (e) => {
        this.setState({ new_password: e.target.value })
    }
    updateInputPasswordRepeat = (e) => {
        this.setState({ new_password_repeat: e.target.value })
    }
    updateInputCurrency = (e) => {
        this.setState({ currency: e.target.value })
    }



    componentDidMount() {
        try {
            this.getVerifiedInfo();
            this.getUserInfo();
            
        }
        catch { }
       
    }


    getVerifiedInfo = () => {
        var self = this;
        const axios = require('axios');
        const options = {
            headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
        };
        axios.get(API + '/check_account')
            .then(function (response) {
                if (response.status == false) {
                    // Handle the error here...
                } else {
                    try{
                     self.setState({ isVerified: response.data.is_verified });
                    
                    }
                    catch{
                        self.setState({ isVerified: false });
                    }
                 
                    // Handle the user data here...
                }

            })
    }


    getUserInfo = () => {
        var self = this;
        const axios = require('axios')
        const options = {
            headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
        };
        axios.get(API + '/get_user_info', options)
            .then(function (response) {
                if (response.status === false) {
                    // Handle the error here...
                } else {
                    self.setState({ username: response.data.username, currency: response.data.currency, email: response.data.email, phone: response.data.phone,first_name: response.data.first_name,last_name: response.data.last_name,country: response.data.country });
                    // Handle the user data here...
                }

            })
    }

    changeUserInfo = () => {
        var self = this;
        const axios = require('axios')
        const options = {
            headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
        };
       
            if (this.state.new_password_repeat == this.state.new_password){
                if (this.state.new_password_repeat.length > 6 && this.state.new_password.length > 6){
                axios.post(API + '/change_user_data',{
                    
                    new_password: this.state.new_password_repeat,new_firstname: this.state.first_name,new_lastname: this.state.last_name,new_username: this.state.username,new_email: this.state.email,new_country: this.state.country,new_phone: this.state.phone,new_currency: this.state.currency},options)
                    .then(function (response) {
                        if (response.status === false) {
                            this.openModal("Invalid credentials. Please try again.");
                            // Handle the error here...
                        } else {
                            self.setState({first_name: response.data.first_name,last_name: response.data.last_name,username: response.data.username,email: response.data.email,country: response.data.country,phone: response.data.phone,currency: response.data.currency});
                            // Handle the user data here...
                        }
        
                    })
                }
               
        }
        else{
            this.openModal("Invalid credentials. Please try again.");
        }
        

       
    }


    addCardData = () => {
        var self = this;
        const axios = require('axios')
        const options = {
            headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
        };
        axios.post(API + '/add_credit_card',{
            card_number: this.state.card_number,holders_name: this.state.holders_name,expiration_date: this.state.expiration_date,cvv_number: this.state.cvv_number},options)
            .then(function (response) {
                if (response.status === false) {
                    this.openModal();
                    // Handle the error here...
                } else {
                    self.setState({first_name: response.data.new_firstname,last_name: response.data.new_lastname,username: response.data.new_username,email: response.data.new_email,country: response.data.new_country,phone: response.data.new_phone,currency: response.data.new_currency});
                    // Handle the user data here...
                }

            })
    }





    render() {
        return (
            <div style={{ color: "white", textAlign: "center" }}>
                <Navbar />
                <Navbar_Bottom />
                <div className="body_div">

                    <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                        <h1>Account Settings</h1>

                        <div>
                            <p><FontAwesomeIcon icon={faQuestionCircle} /> Questions about your account?<a href="/privacy_policy" style={{ color: "#FBC02D" }}> see FAQ</a> </p>


                            {this.state.isVerified ?
                            <Row >
                                <Col sm={4} >
                                    <Col ml={4} style={{ backgroundColor: "#1c1d20", borderRadius: "25px", minHeight: "310px", marginTop: "10px", marginRight: "20px",boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 4px 6px" }}>
                                        <h2 style={{ textAlign: "center", marginTop: "10px" }}>ACCOUNT STATUS
                               
                                                <FontAwesomeIcon icon={faCheckCircle} title="Verified" width="15px" height="15px" style={{ float: "right", marginTop: "5px" }} />

                                            
                                        </h2>
                                        <form action="#">

                                            <div className="file-upload">
                                                <div className="image-upload-wrap">
                                                    {/*  */}
                                                    <Dropzone onDrop={this.handleDrop} disabled={this.state.isVerified} multiple={true} accept="image/*"  />
                                                    {/* <input type="file" name="file" onChange={this.handleDrop} /> */}
                                                </div>
                                                <div className="file-upload-content">
                                                    <img className="file-upload-image" src="#" alt="your image" />
                                                    <div className="image-title-wrap">
                                                        {/* <button type="button" onClick={this.removeUpload()} className="remove-image">Remove <span className="image-title">Uploaded Image</span></button> */}
                                                    </div>
                                                </div>
                                                    <button className="file-upload-btn" disabled={this.state.isVerified} type="button" onClick={e => this.handleUpload(e)}>Verify account</button>
                                                
                                            </div>
                                            {/* <input type="file" id="myFile" name="filename"/> */}

                                        </form>

                                    </Col>
                                    <Col ml={4} style={{ backgroundColor: "#1c1d20", borderRadius: "25px", minHeight: "310px", marginTop: "10px", padding: "15px", maxHeight: "1200px",boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 4px 6px" }} >
                                        <div className="credit_card_container" >
                                            <p className="heading" style={{ marginLeft: "-1px" }}>PAYMENT DETAILS</p>
                                            <form className="card-details ">
                                                <div className="form-group">
                                                    <p className="text-warning mb-0" style={{ marginLeft: "-1px" }}>Card Number</p> <input type="text" className="input-payment" style={{ float: "left", marginTop: "18px" }} name="card-num" placeholder={this.state.card_number} size="30" id="cno" minLength="19" maxLength="19" />
                                                    <img src="https://icon-library.com/images/visa-mastercard-icon/visa-mastercard-icon-9.jpg" width="100px" height="35px" />


                                                </div>
                                                <div className="form-group mb-0">
                                                    <br />
                                                    <p className="text-warning mb-0" style={{ marginLeft: "-1px" }}>Cardholder's Name</p> <input className="input-payment" style={{ float: "left", marginTop: "15px" }} type="text" name="name" placeholder="Name" size="40" />
                                                    <br />   <br />   <br />
                                                </div>
                                                <div className="form-group mb-0">
                                                    <div className="row d-flex" style={{ marginLeft: "-25px" }}>
                                                        <div className="col-sm-4">
                                                            <p className="text-warning mb-0" style={{ marginRight: "-3px" }}>Expiration</p> <input className="input-payment" type="text" name="exp" placeholder="MM/YYYY" style={{ marginLeft: "-45px" }} size="8" id="exp" minLength="7" maxLength="7" />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <p className="text-warning mb-0" style={{ marginRight: "-3px" }}>Cvv</p> <input className="input-payment"  style={{ marginLeft: "-35px" }} type="password" name="cvv" placeholder="&#9679;&#9679;&#9679;" size="4" minLength="3" maxLength="3" />
                                                        </div>
                                                        <div className="col-sm-5 pt-0"> <Button className="btn-deposit rounded-pill btn-primary btn_mobile"> <FontAwesomeIcon icon={faArrowRight} /> SUBMIT</Button> </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Col>
                                </Col>
                                <Col sm={8} style={{ backgroundColor: "#1c1d20", borderRadius: "25px",marginTop: "10px",boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 4px 6px" }}  className="user-info-container">
                                <h2 style={{ textAlign: "center", width: "100%" }}>YOUR INFO</h2>
                                <p style={{width: "100%" }}className="heading ">PERSONAL DATA</p>
                          
                                    <Row>
                                        <Col xs={12} sm={4} >
                                       
                                        <div style={{ marginBottom: "11px" }} className="user_info_card_container"/>
                                        <input className="input_user_info" min="2" placeholder={this.state.first_name} onChange={this.updateInputFirstName} type="text" />
                                        <br/>   <br/>
                                        <input className="input_user_info"  placeholder={this.state.username} onChange={this.updateInputUsername} />
                                        <br />
                                        </Col>
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <input className="input_user_info" placeholder={this.state.last_name} min="3" onChange={this.updateInputLastName} type="text" />
                                        <br /><br />
                                        <input className="input_user_info" min="4" type="email" onChange={this.updateInputEmail} placeholder={this.state.email} />
                                        
                                        </Col>
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <input className="input_user_info" min="3" placeholder={this.state.country} onChange={this.updateInputCountry} type="text" />
                                        <br /><br />
                                      
                                        <input className="input_user_info" min="5" placeholder={this.state.phone} onChange={this.updateInputPhone}/>
                                        </Col>
                                        </Row>
                                        <br />
                                        <p style={{ textAlign: "left", width: "100%" }} className="heading">PASSWORD</p>
                              
                                        <Row >
                                       
                                      
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                            <input className="input_user_info" min="6" placeholder="New Password" type="password" onChange={this.updateInputPassword} />
                                          
                                        </Col>
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <input className="input_user_info" min="6" placeholder="Repeat Password" type="password" onChange={this.updateInputPasswordRepeat} />
                                       
                                        </Col>
                                        <Col xs={12} sm={4}>
                                   
                                            <br /><br />
                
                                        </Col>
                                        </Row>
                                        <br />
                                        <p style={{ textAlign: "left", width: "100%" }} className="heading ">CURRENCY</p>
                                     
                                        <Row>
                                        <br />
                                       
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <select name="currency_field" onChange={this.updateInputCurrency} >
                                                <option disabled defaultValue="choose currency">Change currency:</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="TRY">TRY</option>
                                                <option value="ZAR">ZAR</option>
                                                {/* <option value="CRYPTO" disabled="true">CRYPTO</option> */}
                                        </select>

                                        <br /><br />
                                        </Col>
                                        <Col xs={4}>
                                        
                                        </Col>
                                        <Col xs={4}>
                                        
                                        </Col>
                                        </Row>
                                      
                                       
                                
                                        {/* <p style={{ textAlign: "left", width: "100%" }} className="heading">AFFILIATE DATA</p>
                                        <Row>
                                     
                                        <br />
                                        <Col xs={12}>
                                        <p >You didn`t subscribe to affiliate program yet<a href="#"  className="adress-link"> click here to subscribe</a></p>
       
                                        </Col>
                                        </Row> */}
                                   
                                   <div >
                                   <p style={{bottom: "0",position: "absolute",marginBottom: "80px",right: "0",marginRight: "3%"}}>*To change your data you also have to change your password!</p>
                                    <button style={{bottom: "0",position: "absolute",marginLeft: "-47%",marginBottom: "20px",maxWidth: "94%"}} className="file-upload-btn btn_mobile" type="button" onClick={this.changeUserInfo}>UPDATE USER DATA</button>
                                    </div>
                                </Col>
                            </Row>



:

                            <Row >
                                <Col sm={4} >
                                    <Col ml={4} style={{ backgroundColor: "#1c1d20", borderRadius: "25px", minHeight: "310px", marginTop: "10px", marginRight: "20px",boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 4px 6px" }}>
                                        <h2 style={{ textAlign: "center", marginTop: "10px" }}>ACCOUNT STATUS
                                               <FontAwesomeIcon icon={faExclamationCircle} title="Not Verified" width="15px" height="15px" style={{ float: "right", marginTop: "5px" }} />     
                                        </h2>
                                        <form action="#">

                                            <div className="file-upload">
                                                <div className="image-upload-wrap">
                                                    {/*  */}
                                                    <Dropzone onDrop={this.handleDrop} multiple={true} accept="image/*"  />
                                                    {/* <input type="file" name="file" onChange={this.handleDrop} /> */}
                                                </div>
                                                <div className="file-upload-content">
                                                    <img className="file-upload-image" src="#" alt="your image" />
                                                    <div className="image-title-wrap">
                                                        {/* <button type="button" onClick={this.removeUpload()} className="remove-image">Remove <span className="image-title">Uploaded Image</span></button> */}
                                                    </div>
                                                </div>
                                             
                                                    <button className="file-upload-btn" type="button" onClick={e => this.handleUpload(e)}>Verify account</button>
                                                
                                            </div>
                                            {/* <input type="file" id="myFile" name="filename"/> */}

                                        </form>

                                    </Col>
                                    <Col ml={4} style={{ backgroundColor: "#1c1d20", borderRadius: "25px", minHeight: "310px", marginTop: "10px", padding: "15px", maxHeight: "1200px",boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 4px 6px" }} >
                                        <div className="credit_card_container" >
                                            <p className="heading" style={{ marginLeft: "-1px" }}>PAYMENT DETAILS</p>
                                            <form className="card-details ">
                                                <div className="form-group">
                                                    <p className="text-warning mb-0" style={{ marginLeft: "-1px" }}>Card Number</p> <input type="text" className="input-payment" style={{ float: "left", marginTop: "18px" }} name="card-num" placeholder="1234 5678 9012 ****" size="30" id="cno" minLength="19" maxLength="19" />
                                                    <img src="https://icon-library.com/images/visa-mastercard-icon/visa-mastercard-icon-9.jpg" width="100px" height="35px" />


                                                </div>
                                                <div className="form-group mb-0">
                                                    <br />
                                                    <p className="text-warning mb-0" style={{ marginLeft: "-1px" }}>Cardholder's Name</p> <input className="input-payment" style={{ float: "left", marginTop: "15px" }} type="text" name="name" placeholder="Name" size="40" />
                                                    <br />   <br />  
                                                </div>
                                                <div className="form-group mb-0">
                                                <br />
                                                        <div className="col-sm-4">
                                                        <p className="text-warning mb-0" style={{ marginLeft: "-1px" }}>Expiration</p> <input className="input-payment" style={{ float: "left", marginTop: "15px" }}  type="text" name="exp" placeholder="MM/YYYY" size="8" id="exp" minLength="7" maxLength="7" />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <p className="text-warning mb-0" style={{ marginRight: "-3px" }}>Cvv</p> <input className="input-payment" style={{ right: "0" }} type="password" name="cvv" placeholder="&#9679;&#9679;&#9679;" size="4" minLength="3" maxLength="3" />
                                                        </div>
                                                        <div className="col-sm-5 pt-0"> <Button className="btn-deposit rounded-pill btn-primary btn_mobile"> <FontAwesomeIcon icon={faArrowRight} /> SUBMIT</Button> </div>
                                                   
                                                </div>
                                            </form>
                                        </div>
                                    </Col>
                                </Col>
                                <Col sm={8} style={{ backgroundColor: "#1c1d20", borderRadius: "25px",marginTop: "10px",boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 4px 6px" }}  className="">
                                <h2 style={{ textAlign: "center", width: "100%" }}>YOUR INFO</h2>
                                <p style={{width: "100%" }}className="heading user_info_card">PERSONAL DATA</p>
                                <br />
                                    <Row>
                                        <Col xs={12} sm={4} >
                                       
                                        <div style={{ marginBottom: "11px" }} className="user_info_card_container"/>
                                        <input className="input_user_info" min="2" placeholder={this.state.first_name} onChange={this.updateInputFirstName} type="text" />
                                        <br/>   <br/>
                                        <input className="input_user_info"  placeholder={this.state.username} onChange={this.updateInputUsername} />
                                        <br />
                                        </Col>
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <input className="input_user_info" placeholder={this.state.last_name} min="3" onChange={this.updateInputLastName} type="text" />
                                        <br /><br />
                                        <input className="input_user_info" min="4" type="email" onChange={this.updateInputEmail} placeholder={this.state.email} />
                                        
                                        </Col>
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <input className="input_user_info" min="3" placeholder={this.state.country} onChange={this.updateInputCountry} type="text" />
                                        <br /><br />
                                      
                                        <input className="input_user_info" min="5" placeholder={this.state.phone} onChange={this.updateInputPhone}/>
                                        </Col>
                                        </Row>
                                        <br />
                                        <p style={{ textAlign: "left", width: "100%" }} className="heading user_info_card">PASSWORD</p>
                                        <br />
                                        <Row >
                                       
                                      
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                            <input className="input_user_info" min="6" placeholder="New Password" type="password" onChange={this.updateInputPassword} />
                                          
                                        </Col>
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <input className="input_user_info" min="6" placeholder="Repeat Password" type="password" onChange={this.updateInputPasswordRepeat} />
                                       
                                        </Col>
                                   
                                        </Row>
                                        <br />
                                        <p style={{ textAlign: "left", width: "100%" }} className="heading user_info_card">CURRENCY</p>
                                        <br />
                                        <Row>
                                        <br />
                                       
                                        <Col xs={12} sm={4}>
                                        <div style={{ marginBottom: "11px" }} />
                                        <select name="currency_field" onChange={this.updateInputCurrency} >
                                                <option disabled defaultValue="choose currency">Change currency:</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="TRY">TRY</option>
                                                <option value="ZAR">ZAR</option>
                                                {/* <option value="CRYPTO" disabled="true">CRYPTO</option> */}
                                        </select>

                                        <br /><br />
                                        </Col>
                                        <Col xs={4}>
                                        
                                        </Col>
                                        <Col xs={4}>
                                        
                                        </Col>
                                        </Row>
                                      
                                       
                                
                                        {/* <p style={{ textAlign: "left", width: "100%" }} className="heading">AFFILIATE DATA</p>
                                        <Row>
                                     
                                        <br />
                                        <Col xs={12}>
                                        <p >You didn`t subscribe to affiliate program yet<a href="#"  className="adress-link"> click here to subscribe</a></p>
       
                                        </Col>
                                        </Row> */}
                                   
                                   <div >
                                   <p style={{bottom: "0",position: "absolute",marginBottom: "80px",right: "0",marginRight: "3%"}}>*To change your data you also have to change your password!</p>
                                    <button style={{bottom: "0",position: "absolute",marginLeft: "-47%",marginBottom: "20px",maxWidth: "94%"}} className="file-upload-btn btn_mobile" type="button" onClick={this.changeUserInfo}>UPDATE USER DATA</button>
                                    </div>
                                </Col>
                            </Row>

                                    }






                            <ModalPopUp
                                modalTitle="ERROR"
                                isOpen={this.state.isOpen}
                                modalMessage={this.state.modalMessage}
                                closeModal={this.closeModal}
                            />
                            <br />
                        </div>

                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}

const uploadImage = async file => {
    try {
      const axios = require('axios');
      console.log("Upload Image", file);
      const formData = new FormData();
      formData.append("filename", file);
      formData.append("destination", "images");
      formData.append("create_thumbnail", true);
      const config = {
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "content-type": "multipart/form-data",
          "filename" : file + "_doc"
        }
      };
      const url = API + "/upload_docs";
  
      const result = await axios.post(url, formData, config);
      console.log("REsult: ", result);
    } catch (error) {
      console.error(error);
    }
  };


  

export default MyProfile;