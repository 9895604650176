import React from 'react';

import { Button} from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import logo from './assets/11.png';
import logo1 from './assets/12.png';


// TO DO: REACT BOOTSTRAP GRID SPLIT col(8-3)
export const Logo = () => (
    <div>
    <a className="navbar-brand" href="/" >
        <img src={logo} alt={"logo"} className="logo" />
    </a>
   
</div>
)

export const Buttons = (props) => (

    <div className="btn-toolbar-user" role="toolbar" aria-label="Toolbar with button groups">

        <div className="btn-group mr-2" role="group" aria-label="First group">
            <button className="btn btn-outline gray login-mobile" onClick={props.loginUser} id="loginBtn" type="submit" >Log In</button>
        </div>
        <div className="btn-group mr-2" role="group" aria-label="Second group">
            <Button className="btn btn-primary gray register-mobile" style={{ backgroundColor: "#ffffff" }} type="submit"  ><a className="register_button" href="/register">Join Now</a>
            </Button>
        </div>

        
    </div> 
)

