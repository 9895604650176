import React, { Component } from "react";
import Navbar from './Navbar';
import Navbar_Bottom from './Navbar_bot';
import Footer from "./Footer";
import { RegisterForm } from './Header/Buttons/RegisterForm';
import { Route,Redirect} from 'react-router-dom';

import { ModalPopUp } from '../components/Header/Buttons/ModalPopUp'

import API from "../utils/PlatformAPI";

export default class Register extends Component {

	constructor(props) {
		super(props)


		this.state = {
			username: null,
			first_name: null,
			last_name: null,
			email:null,
			password: null,
			repeat_password: null,
			zip: null,
			country: null,
			currency: null,
			phone: null,
			phone_code: null,
			value: null,
			redirect: false,
			isError: false,
			isOpen: true
		}
	}
	

	openModal = () => this.setState({ isOpen: true});
	closeModal = () => this.setState({ isOpen: false });

	changeUsername= (e) => {
		this.setState({ username: e.target.value })
	  }
	changePassword = (e) => {
		this.setState({ password: e.target.value })
	  }
	changePasswordRepeat = (e) => {
		this.setState({ repeat_password: e.target.value })
	  }
	changeEmail = (e) => {
		this.setState({ email: e.target.value })
	  }
	changeFirstName = (e) => {
		this.setState({ first_name: e.target.value })
	  }  
	changeLastName = (e) => {
		this.setState({ last_name: e.target.value })
	  }
	changeZip = (e) => {
		this.setState({ zip: e.target.value })
	  }
	changeCountry = (e) => {
		this.setState({ country: e.target.value })
	  }
	changeCurrency = (e) => {
		this.setState({ currency: e.target.value })
	  }
	changePhoneCode = (e) => {
		this.setState({ phone_code: e.target.value })
	  }
	changePhoneNumber = (e) => {
		this.setState({ phone: e.target.value })
	  }

	register = async () => {
		const axios = require('axios');
		let self = this;
		let pass = '';
		if (self.state.password === self.state.repeat_password){
			pass = this.state.password
		}
		
		axios.post(API + '/register', {
			username: this.state.username,
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			email: this.state.email,
			password: pass,
			currency: this.state.currency,
			phone:this.state.phone,
			zip:this.state.zip,
			country:this.state.country
		})
		.then(function (response) {
			if (response == 'User was created') {
				window.location.replace("/");
			  }
			  else if (response.status === 201){
				window.location.replace("/"); 
			  }
			  else if (response.status > 300){
				self.setState({ error: true }); 
			}
			  else{
				self.setState({ error: true }); 
			  }
		  })
		  .catch(function (error) {
			if (error.response.status >= 400) {
				self.setState({ isError: true });
			}
		  })

	}

	componentDidMount() {

	}

	render() {
		return (
			<div className="body_div">
				<Navbar />
				<Navbar_Bottom />

			<RegisterForm
			changeUsername={(e) => this.changeUsername(e)}
			changeFirstName={(e) => this.changeFirstName(e)}
			changeLastName={(e) => this.changeLastName(e)}
			changeEmail={(e) => this.changeEmail(e)}
			changePassword={(e) => this.changePassword(e)}
			changePasswordRepeat={(e) => this.changePasswordRepeat(e)}
			changeZip={(e) => this.changeZip(e)}
			changeCountry={(e) => this.changeCountry(e)}
			changeCurrency={(e) => this.changeCurrency(e)}
			changePhoneCode={(e) => this.changePhoneCode(e)}
			changePhoneNumber={(e) => this.changePhoneNumber(e)}
			registerUser={this.register}
			/>
			 {this.state.isError ?
			  <ModalPopUp
              modalTitle="ERROR"
              isOpen={this.state.isOpen}
              modalMessage={"Wrong Credentials.Please try again"}
              closeModal={this.closeModal}
            />
          :
		  <>
          </>
          }
				<Footer />
			</div>

		);
	}
}