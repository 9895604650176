export default [
  {
    pageURL: "/live_in_play",
    id: 1,
    title: "error",
    pageName: "LIVE IN PLAY"
  },
  {
    pageURL: "/sportsbook",
    id: 2,
    title: "error",
    pageName: "SPORTSBOOK"
  },
  {
    pageURL: "/casino",
    id: 3,
    title: "NETENT, EGT, NOVOMATIC, AMATIC, WAZDAN, PRAGMATIC, PLAY AND GO ...",
    pageName: "CASINO"
  },
  {
    pageURL: "/virtual_sports",
    id: 4,
    title: "Horse, football, basketball, NFL, NBA, tennis, dog, racing, NHL, Wagers ...",
    pageName: "VIRTUAL SPORTS"
  },
  {
    pageURL: "/promotions",
    id: 5,
    title: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer...",
    pageName: "PROMOTIONS"
  },
  {
    pageURL: "/about_us",
    id: 6,
    title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum...",
    pageName: "ABOUT US"
  },
  {
    pageURL: "/terms_and_coditions",
    id: 7,
    title: "Account , Services, Betting, Legal, Return, Field, Currency, Bonus, Deposits, Money, Financial Limitations , Payments / Withdrawals",
    pageName: "TERMS AND CODITIONS"
  },
  {
    pageURL: "/rules_and_regulations",
    id: 8,
    title: "Rules, Who is in control of your information?,Our Data Protection Officer,Personally identifiable information,Non-personally identifiable information and traffic analysis,Cookies,How and why we use your personal information,Providing our products and services, Requirements, Monitoring, Marketing, Rights, Personal information, Sharing, Security, Retention, Third-Party Practices, Analytics, Disclaimer",
    pageName: "RULES AND REGULATIONS"
  },
  {
    pageURL: "/privacy_policy",
    id: 9,
    title: "Rules, Who is in control of your information?,Our Data Protection Officer,Personally identifiable information,Non-personally identifiable information and traffic analysis,Cookies,How and why we use your personal information,Providing our products and services, Requirements, Monitoring, Marketing, Rights, Personal information, Sharing, Security, Retention, Third-Party Practices, Analytics, Disclaimer",
    pageName: "PRIVACY POLICY"
  },
  {
    pageURL: "/contact_us",
    id: 10,
    title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not, contacts, contact us, send a message",
    pageName: "CONTACT US"
  }
];
