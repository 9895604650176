import {
    SEND_DEPOSIT_REQ,
    GET_DEPOSIT_RES,
    GET_DEPOSIT_ERR,
    } from '../actions/deposit.action';
    
    const initialState = {
      isLoading: false,
      // user_ballance: '',
      // currency: '',
      deposit: '',
      isError: false,
    };
    
    function auth(state = initialState, action) {
      switch (action.type) {
        case SEND_DEPOSIT_REQ:
          return {
            ...state,
            isLoading: true
          }
        case GET_DEPOSIT_RES:
          return {
            ...state,
            // username: action.data,
            // token: action,
            isLoading: false,
            deposit: action.deposit,
            // currency: action.currency,
            // user_ballance: action.user_ballance
          }
        case GET_DEPOSIT_ERR:
          return{
            ...state,
            error: action.error
          }
        // case CLEAR_LIST:
        //   return {
        //     ...state,
        //     currency: '',
        //     user_ballance: ''
        //   }
    
        default:
          return state;
      }
    }
    
    export default auth