import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import axios from "axios";
import API from "../utils/PlatformAPI";
import { CardDeck, Card, Button, ButtonToolbar, ButtonGroup, Container, Row } from 'react-bootstrap';
import { ModalPopUp } from '../components/Header/Buttons/ModalPopUp'
// import CircleLoader from "react-spinners/CircleLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";

import { Link } from 'react-router-dom';

import { BrowserView, MobileView } from 'react-device-detect';

import store from '../redux/store';

const state = store.getState();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export default class LiveCasino extends Component {



  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    PostsLoading: false,
    posts: [],
    isActive: false,
    brandState: [],
    mobileArr: [],
    desktopArr: [],
    user_id: state.auth.userId,
    isMobile: [],
    logged: state.auth.logged,
    isToggleOn: [false, false, false, false, false, false, false, false],
    error: false,
    isOpen: true,
    page: 1,
    prevY: 1
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

//get live games list and populate to arr
  fetchPosts(page, filter) {
    var self = this;
    self.setState({ PostsLoading: true })
    const axios = require('axios');
    axios.get(API + '/live_slots_get_games?page=' + page)
      .then(function (response) {


        var desktopArr = [];
        var mobileArr = [];

        for (const item of response.data.items) {
          console.log(item);

              if (item.provider == 'eZugi' || item.provider == 'Betgames' || item.provider == 'XPG' || item.provider == 'Evolution2' || item.provider == 'Vivogaming' || item.provider == 'Lotto Instant Win' || item.provider == 'Super Spade Games') {
                if (item.is_mobile == true) {
                  desktopArr.push(item)
                  self.setState({ desktopArr: self.state.desktopArr.concat(item) })
                  mobileArr.push(item)
                  self.setState({ mobileArr: self.state.mobileArr.concat(item) })
                }
                if (item.name.includes("Mobile")) {
                  mobileArr.push(item)
                  self.setState({ mobileArr: self.state.mobileArr.concat(item) })
                }
              }
            
          
        }
        self.setState({ posts: response.data, brandState: response.data, PostsLoading: false })
        
      })
      .catch(function (error) {
        self.setState({ error: true });
        if (error.status > 299) {
          self.setState({ error: true });
        }
        else {
          self.setState({ error: true });
        }
      })
    //the rest of the code


  };

  componentDidMount() {

    document.title = "Betdome|Live Casino"

    for (var i = 0; i < 70; i++) {
      this.fetchPosts(i, '');
    }
    

  }



  render() {

    const loadingCSS = {
      height: "10px",
      margin: "30px"
    };
    const loadingTextCSS = { display: this.state.PostsLoading ? "block" : "none", color: "white" };
    const renderList = this.state.desktopArr.map(card => (



      <BrowserView>
        <CardDeck style={{ margin: "0 auto" }}>
          <Card style={{ width: "18rem" }} variant="card" className="box">
            <Card.Img variant="top" style={{ borderRadius: "8px" }} src={card.image} />
            <div className="overlay" >
              <div className="content_overlay">
                <Link className="fill-div" to={{
                  pathname: '/live_game',
                  state: { game_uuid: card.uuid }
                }} >{card.name}</Link>
              </div>
            </div>
          </Card>
        </CardDeck>
      </BrowserView>
    ))

    const mobileList = this.state.mobileArr.map(card => (


      <MobileView>
        <CardDeck style={{ margin: "0 auto" }}>
          <Card variant="card" className="box">
            <Card.Img variant="top" style={{ borderRadius: "8px" }} src={card.image} />
            <div className="overlay" >
              <div className="content_overlay">
                <Link className="fill-div" to={{
                  pathname: '/live_game',
                  state: { game_uuid: card.uuid }
                }} >{card.name}</Link>
              </div>
            </div>
          </Card>
        </CardDeck>
      </MobileView>

    ))




    return (
      <div style={{ backgroundColor: "#263238",Height: "50px" }}>
        <Navbar />
        <Navbar_Bottom />

        <div className="grid" >
          {this.state.loading ?
           <></>
            : renderList

          }
          <MobileView>
            {mobileList}
          </MobileView>
          {this.state.logged ?
            <>
            </>
            :
            <ModalPopUp
              modalTitle="ERROR"
              isOpen={this.state.isOpen}
              modalMessage={"You must be logged to play games.Please try again later"}
              closeModal={this.closeModal}
            />
          }

        </div>



        <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
          style={loadingCSS}
        >


          <div className="sweet-loading d-flex justify-content-center">
            <PropagateLoader css={override} size={15} color={"#FBC02D"} loading={this.state.PostsLoading} speedMultiplier={1.2} />
          </div>

        </div>

        <Footer />

      </div>

    );
  }


}

