import React, { useEffect, useState, setState, Link } from "react";

import { connect } from 'react-redux'
import { login, logout } from "../actions/auth.action";

import { deposit } from "../actions/deposit.action";

import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'

import { Buttons, AccountDropdown, Logo, LoginPopup, DepositPopup } from './Header/Buttons';

import Cookies from 'js-cookie';
import { HeadeMenu } from './Header';

import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import { decode } from 'string-encode-decode'
import store from '../redux/store';
import API from "../utils/PlatformAPI";

const state = store.getState();


class NavbarComp extends React.Component {



  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      isOpen: false,
      isDeposit: false,
      isLoading: state.auth.isLoading,
      session: [],
      userId: state.auth.userId,
      balance: state.auth.balance,
      currency: '',
      deposit: '',
      token: null,
      userLogin: '',
      userPassword: '',
      errorText: '',
    };
  }


  //pop-up inputs

  changeDepositSum = (e) => {
    this.setState({
      deposit: e.target.value
    })
  }
  changeDepositCurrency = (e) => {
    this.setState({
      currency: e.target.value
    })
  }


  changeLogin = (e) => {
    this.setState({
      userLogin: e.target.value
    })
  }

  changePassword = (e) => {
    this.setState({ userPassword: e.target.value })
  }


  sendDepositData = () => {
    this.props.depositClick(
      {
        deposit: this.state.deposit,
        currency: this.state.currency
      }
    )
    this.setState({ isDeposit: false })
  }


  //login action
  sendUserData = () => {
    if (state.auth.balance != this.state.currency + ':  ') {
      this.setState({ token: decode(Cookies.get('J_UT')), balance: state.auth.balance, userId: state.auth.userId, logged: true })
    }
    else {
      this.setState({ token: decode(Cookies.get('J_UT')), balance: state.auth.balance + '0', userId: state.auth.userId, logged: true })
    }
    this.props.loginClick(
      {
        username: this.state.userLogin,
        password: this.state.userPassword,
        balance: this.state.balance,
        currency: this.state.currency,
        userId: this.state.userId
      }
    )

    if (this.state.token == null) {
      this.setState({ visible: false, isOpen: false, errorText: 'Wrong credentials' })
    }
    else {
      this.setState({ visible: false, isOpen: true, errorText: '', balance: this.state.balance, userId: this.state.userId })
    }
  }

  //logout
  setLogout = () => {
    this.props.userLogout()
    window.localStorage.removeItem("state");
    this.setState({ logged: false })
    Cookies.remove('J_UT', { path: '' })
  }

  //update user ballance
  balanceUpdate = () => {
    var self = this;
    const axios = require('axios')
    const options = {
      headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
    };
    axios.get(API + '/get_balance', options)
      .then(function (response) {
        if (response.status >= 300) {
          self.setLogout()
        }
        else if (response.status == 401) {
          self.setLogout()
        }
        else if (response.status == 200) {
          try {
            self.setState({ balance: response.data.currency + ": " + response.data.user_ballance.toFixed(2) + " ", userId: response.data.user_id, logged: true })
          }
          catch {
            self.setLogout()
          }
          // localStorage.removeItem("balance")
          // Handle the user data here...
        }

      })
      .catch(error => {
        self.setLogout()
      })

  }


  componentDidMount() {
    this.balanceUpdate();


    this.setState({ balance: state.auth.balance, isOpen: state.auth.isOpen, userId: state.auth.userId });

    let burger = document.getElementById('burger'),
      nav = document.getElementById('mobile-nav');

    burger.addEventListener('click', function (e) {
      this.classList.toggle('is-open');
      nav.classList.toggle('is-open');
    });



  }








  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  openDeposit = () => this.setState({ isDeposit: true });
  closeDeposit = () => this.setState({ isDeposit: false });


  render() {
    const {
      errorText,
      isLoading,
    } = this.props

    //login pop-up state
    return (
      <div className="header-holder">
        <div class="mobile-menu-container" >
          <button id="burger" class="open-mobile-nav" >
            <span class="burger"></span>
            <span class="burger-text"></span>
          </button>
          <nav class="mobile-nav" id="mobile-nav" >
            <ul>
              <li>
                <a href="/live_in_play">LIVE-IN-PLAY</a>
              </li>
              <li>
                <a href="/sportsbook">SPORTSBOOK</a>
              </li>
              <li>
                <a href="/casino">CASINO</a>
              </li>
              <li>
                <a href="/live_casino">LIVE CASINO</a>
              </li>
              <li>
                <a href="/virtual_sports">VIRTUAL SPORTS</a>
              </li>
              <li>
                <a href="/promotions">PROMOTIONS</a>
              </li>
            </ul>
          </nav>

        </div>
        <nav className="navbar navbar-expand-md navbar-dark sticky-top navbar-top" fixed="top" style={{ backgroundColor: "#263238" }}>
          <Logo />
          <HeadeMenu />

          {

            !this.props.loggedIn ?
              <Buttons
                loginUser={this.openModal}
              />
              :
              <AccountDropdown
                user_balance={this.state.balance}
                set_visible={this.setVisible}
                set_invisible={this.setLogout}
                openModal={this.openModal}
                openDeposit={this.openDeposit}
              />

          }

          <LoginPopup
            isOpen={this.state.isOpen}
            errorText={this.state.errorText}
            closeModal={this.closeModal}
            changeLogin={(e) => this.changeLogin(e)}
            changePassword={(e) => this.changePassword(e)}
            userLogin={this.state.userLogin}
            userPass={this.state.userPassword}
            sendUserData={() => this.sendUserData()}
          />
          <DepositPopup
            isDeposit={this.state.isDeposit}
            changeDepositSum={(e) => this.changeDepositSum(e)}
            changeDepositCurrency={(e) => this.changeDepositCurrency(e)}
            sendDepositData={() => this.sendDepositData()}
            closeDeposit={this.closeDeposit} />

        </nav>
      </div>
    );


  }

}

const mapStateToProps = ({ auth }) => ({
  visible: auth.visible,
  isOpen: auth.isOpen,
  deposit: auth.deposit,
  errorText: auth.error,
  isLoading: auth.isLoading,
  loggedIn: auth.logged,
  balance: auth.balance,
  logged: auth.logged,
  currency: auth.currency,
  username: auth.username,
  password: auth.password,
  userId: auth.userId
})

const mapDispatchToProps = (dispatch) => ({
  loginClick: (data) => {
    // console.log(data);
    dispatch(login(data))
  },
  depositClick: (data) => {
    // console.log(data);
    dispatch(deposit(data))
  },
  userLogout: (data) => {
    dispatch(logout(data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComp);