import React from "react";
import ApiData from "../utils/search_core";

import  Navbar  from '../components/Navbar';
import  Navbar_Bottom  from '../components/Navbar_bot';
import Footer from "../components/Footer";
import { Card, Spinner, Button, CardDeck } from 'react-bootstrap';


export default class SearchResultsPage extends React.Component {
    state = {
        isLoading: true,
        searchText: "",
        searchResults: []
    };

    handleSearch = () => {
        let searchText = this.props.location.state.searchText;
        let results = ApiData.filter(item => item.title.includes(searchText));
        this.setState({
            isLoading: false,
            searchText: searchText,
            searchResults: results
        });
    };

    componentDidMount() {
        this.handleSearch();
    }

    componentDidUpdate(prevProps) {
        let prevSearch = prevProps.location.state.searchText;
        let newSearch = this.props.location.state.searchText;
        if (prevSearch !== newSearch) {
            this.handleSearch();
        }
    }

    goToCarddetails = (cardURL) => {
        // you can manage here to pass the clicked card id to the card details page if needed
        const url = window.open(cardURL, "_blank");
        // url.focus();
        // this.props.history.push(cardURL);
      
      }

    render() {

        let toRender = this.state.isLoading ? (
            <h1>Loading...</h1>
        ) : (
            <>
                {/* <h1 style={{textAlign: "center"}}>Search Results</h1> */}
                <div style={{textAlign: "center"}}>
                    <p style={{marginLeft: "40px"}}>Search for: "{this.state.searchText}"  Matches found: {this.state.searchResults.length}</p>
                </div>
                {this.state.searchResults.length > 0 ? (
                       
                       this.state.searchResults.map(card => (
                        <>
                         <CardDeck>
                        <Card style={{backgroundColor: "red",height: "200px",marginLeft: "40px" ,marginRight: "40px" }} variant="card" className="box">
                
                            <div onClick={() => this.goToCarddetails(card.pageURL)} >
                                <h2 style={{textAlign: "left"}}>{card.pageName}</h2>
                                <p max="600">{card.title}</p>
                                <Button style={{marginBottom: "25px",bottom: "0",position: "absolute",right: "0",borderRadius: "20px 0 0 20px"}} onClick={() => this.goToCarddetails(card.pageURL)}>GO TO PAGE</Button>
                        </div>
                      </Card>
                      </CardDeck>
                       </>
                        ))
                      
              
   

                ) : (
                    <h2>NO RESULTS FOUND</h2>
                )}
                <br/>
            </>
        );

        return (
        <>
        <Navbar/>
        <Navbar_Bottom/>

        <div style={{backgroundColor: "#282c34",color: "#fff"}}>{toRender}</div>
        

        <Footer/>
        </>
        )
    }
}
