import {
  SEND_LOGIN_REQ,
  GET_LOGIN_RES,
  GET_LOGIN_ERR,
  LOGOUT
} from './../actions/auth.action';

const initialState = {
  // token: null,
  isLoading: false,
  logged: false,
  isOpen: false,
  isError: false,
  AuthorizationSignatureKey: '',
  userId: '',
  balance: '',
  currency: ''
};

function auth(state = initialState, action) {
  switch (action.type) {
    case SEND_LOGIN_REQ:
      return {
        ...state,
        isLoading: true,
        logged: false,
      }
    case GET_LOGIN_RES:
      return {
        ...state,
        isLoading: false,
        isOpen: action.isOpen,
        logged: true,
        AuthorizationSignatureKey: "MIIC7TCCAdUCAQAwgY4xCzAJBgNVBAYTAlVBMQ0wCwYDVQQIDARLeWl2MQ0wCwYDVQQHDARLeWl2MRUwEwYDVQQKDAxEYWJvbWJHYW1pbmcxDDAKBgNVBAsMA0RCRzEXMBUGA1UEAwwOTWFkZSBJb2JZ",
        userId: action.userId,
        balance: action.balance,
        currency: action.currency
      }
    case GET_LOGIN_ERR:
      return{
        ...state,
        error: action.error,
        logged: false,
      }
    case LOGOUT:
      return {
        ...state,
        logged: false
      }

    default:
      return state;
  }
}

export default auth