import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch,faUser } from '@fortawesome/free-solid-svg-icons'
import AppSearch from "../../pages/VirtualSports"

export const HeaderSearchbar = (props) => (
    <form className="form-inline">
          <div className="wrap sm-12">
          <div className="searchbar">
          <input className="search_input" type="text" name="" placeholder="Search..."/>
          <a href="#" className="search_icon"><FontAwesomeIcon icon={faSearch} /></a>
        </div>

          </div>
          <div className="ml-12 sm-12 breadclumbs"><h2>Home</h2></div>
        </form>

)