import React, { Component } from "react";

import '../Main.css';
import { Container, Row, Col, Table, Button, Dropdown, DropdownButton, } from 'react-bootstrap';

import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';

import Carousel_comp from '../components/Carousel';

import Footer from "../components/Footer";

import API from "../utils/PlatformAPI";

import banner1 from  "../components/images/home_banner_1.png";
import banner2 from  "../components/images/home_banner_2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins,faBell,faUser,faHourglassEnd,faGamepad } from '@fortawesome/free-solid-svg-icons'

class Home_page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usersTableData: [],
            gamesTableData: [],
            errors: null
        }
    }

    getTopUsers() {
        var self = this
        const axios = require('axios')
        // bodyFormData.append('User_ID', '1');
        axios.get(API + '/get_top_winners')
            .then(response => self.setState({ usersTableData: JSON.parse(JSON.stringify(response.data.detail)) }));
        //    this.setState({tableData: JSON.stringify(response.data.Response[0][0]}))
    }

    getTopGames() {
        var self = this
        const axios = require('axios')
        // bodyFormData.append('User_ID', '1');
        axios.get(API + '/get_top_games')
            .then(response => self.setState({ gamesTableData: JSON.parse(JSON.stringify(response.data.detail)) }));
        //    this.setState({tableData: JSON.stringify(response.data.Response[0][0]}))
    }


    componentDidMount() {
        document.title = "Betdome|Home"
        this.getTopUsers();
        this.getTopGames();
    }

    render() {
        return (
            <div style={{ backgroundColor: "#323C44", margin: "0", backgroundColor: "#1d2227" }}>
                <Navbar />
                <Navbar_Bottom />

                <Carousel_comp />
                {/* <div style={{textAlign: "center"}}>
       <h1>TOP GAMES</h1>
       </div> */}
       <div className="home_banner">
       <Row style={{ margin: "0" }}>
                <Col xs="12" lg="2">
                        <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                        <img src={banner1} style={{position: "absolute",width: "300px",height: "300px"}}/>
                        </div>
                    </Col>
                    <Col xs="12" lg="10">
                        <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                            <h2 style={{textAlign: "right"}}>Lorem?</h2>
                            <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                            <button style={{float: "right",marginBottom: "20px"}} className="btn btn-primary">PLAY</button>
                        </div>
                        
                    </Col>

                </Row>
       </div>
                <br />
                <Row style={{ margin: "0" }}>
                <Col >
                        <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                            <Table striped hover variant="dark" className="user_table"  >
                                <thead>
                                    <tr>
                                        <th style={{ color: "#FBC02D" }}>TOP GAMES</th>
                                        <th>TIMES PLAYED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.gamesTableData
                                            .map(function (data) {
                                                return (
                                                    <tr>
                                                        <td>{data.game_name}</td>
                                                        <td>{data.times_played}</td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col>
                        <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                            <Table striped hover variant="dark" className="user_table"  >
                                <thead>
                                    <tr>
                                        <th style={{ color: "#FBC02D" }}>TOP WINNERS</th>
                                        <th>WON</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.usersTableData
                                            .map(function (data) {
                                                return (
                                                    <tr>
                                                        <td>{data.player_id}</td>
                                                        <td>{data.won}</td>
                                                    </tr>
                                                );
                                            })
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </Col>

                </Row>


                <br />
                <div className="home_banner">
                <Row style={{ margin: "0" }}>
                <Col xs="12" lg="10">
                <div>
                            <h2 style={{textAlign: "left"}}>Lorem?</h2>
                            <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"</p>
                            <button style={{float: "left",marginBottom: "20px"}} className="btn btn-primary">PLAY</button>
                        </div>
                    </Col>
                    <Col xs="12" lg="2">
                    <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                        <img src={banner2} style={{position: "absolute",width: "94%",height: "94%",marginRight: "40px"}}/>
                        </div>
                       
                        
                    </Col>

                </Row>
                </div>

                {/* <br /><br />
                <Row style={{ margin: "0" }}>
                    <Col xs lg="4">
                        <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                            <Table striped hover variant="dark" className="user_table"  >
                                <thead>
                                    <tr>
                                        <th style={{ color: "#FBC02D" }}>LIVE LEADERBOARDS</th>
                                        <th>TIMES PLAYED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.gamesTableData
                                            .map(function (data) {
                                                return (
                                                    <tr>
                                                        <td>{data.game_name}</td>
                                                        <td>{data.times_played}</td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col xs lg="8">
                        <div style={{ paddingLeft: "13px", paddingRight: "13px" }}>
                            <Table striped hover variant="dark" className="user_table"  >
                                <thead>
                                    <tr>
                                        <th style={{ color: "#FBC02D" }}>TOP JACKPOTS</th>
                                        <th>WON</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.usersTableData
                                            .map(function (data) {
                                                return (
                                                    <tr>
                                                        <td>{data.player_id}</td>
                                                        <td>{data.won}</td>
                                                    </tr>
                                                );
                                            })
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </Col>

                </Row> */}
                <br/>
{/*                 
                <Row >
                <div className="home_banner_end" style={{display: "block",marginLeft: "auto",marginRight: "auto"}}>
                    <Col><FontAwesomeIcon icon={faCoins} style={{color: "white",width: "40px",height: "40px"}}/>
                    <p>LIMIT ON DEPOSIN</p>
                    </Col>
                    </div>
                    <div className="home_banner_end" style={{display: "block",marginLeft: "auto",marginRight: "auto"}}>
                    <Col><FontAwesomeIcon icon={faBell} style={{color: "white",width: "40px",height: "40px"}}/>
                    <p>QUICK SUPPORT</p>
                    </Col>
                    </div>
                    <div className="home_banner_end" style={{display: "block",marginLeft: "auto",marginRight: "auto"}}>
                    <Col><FontAwesomeIcon icon={faUser} style={{color: "white",width: "40px",height: "40px"}}/>
                    <p>EASY VERIFICATION</p>
                    </Col>
                    </div>
                    {/* <div className="home_banner_end" style={{display: "block",marginLeft: "auto",marginRight: "auto"}}>
                    <Col><FontAwesomeIcon icon={faGamepad} style={{color: "white",width: "40px",height: "40px"}}/>
                    <p>BIG GAMES LIST</p>
                    </Col>
                    </div> 
                    <div className="home_banner_end" style={{display: "block",marginLeft: "auto",marginRight: "auto"}}>
                    <Col><FontAwesomeIcon icon={faHourglassEnd} style={{color: "white",width: "40px",height: "40px"}}/>
                    <p>FAST WIDRAWAL</p>
                    </Col>
                    </div>
                </Row> */}
                
                <br/>
                {/* <button style={{marginBottom: "20px",marginLeft: "45%"}} className="btn btn-primary">JOIN TO PLATFORM</button>
                <br/> */}


                <Footer />
            </div>

        );
    }
}

export default Home_page;
