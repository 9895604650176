import axios from 'axios';
import Cookies  from 'js-cookie';
import {encode} from 'string-encode-decode'
import API from '../utils/PlatformAPI'

// import { getCookie, setCookie, expireCookie } from 'redux-cookie';
export const SEND_LOGIN_REQ = "SEND_LOGIN_REQ";
export const GET_LOGIN_RES = "GET_LOGIN_RES";
export const GET_LOGIN_ERR = "GET_LOGIN_ERR";
export const LOGOUT = "LOGOUT";



export const login = (data) => (dispatch) => {
  dispatch({ type: SEND_LOGIN_REQ,isLoading: true})
  axios.post(API + '/login', data)
    .then(res => {    
      // console.log('§%%$%$§ DATA from action', data)
      // setCookie("J_UT", res.data.token);
      // const cook = getCookie('J_UT');
      // alert(cook);
      Cookies.set("J_UT", encode(res.data.token), {httpOnly: false});
      const options = {
        headers: {'Authorization': 'Token ' + res.data.token}
      };
      axios.get(API + '/get_balance', options)
      .then( res => {
        dispatch({
          type: GET_LOGIN_RES,
          currency: res.data.currency,
          balance: res.data.currency + ": " + res.data.user_ballance.toFixed(2) + " ",
          userId: res.data.user_id,
          isOpen: false,
        })
        window.location.reload();
        //  localStorage.setItem("ballance",res.data.currency + ": " + res.data.user_ballance.toFixed(2) + " ");
      })
      
    })
    
    .catch(error => {
      dispatch({
        type: GET_LOGIN_ERR,
        logged: false,
        error: 'Wrong login or password ',
        isOpen: true
      })
    })
};


export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT })
  return LOGOUT
};