import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import axios from "axios";
import Marquee from "react-fast-marquee";

import API from "../utils/PlatformAPI";
import { CardDeck, Card, Button, ButtonToolbar, ButtonGroup, Container, Row } from 'react-bootstrap';
import { ModalPopUp } from '../components/Header/Buttons/ModalPopUp'

import PropagateLoader from "react-spinners/PropagateLoader";

import { css } from "@emotion/react";

import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import store from '../redux/store';

const state = store.getState();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const brandButtonsTop = [
  { name: "all", value: "0" },
  { name: "netent", value: "10" },
  { name: "egt", value: "11" },
  { name: "novomatic", value: "12" },
  { name: "amatic", value: "13" },
  { name: "wazdan", value: "17" },
  { name: "pragmatic", value: "18" },
  { name: "play n go", value: "19" },

  { name: "BetBy", value: "BetBy" },
  { name: "BetGames.TV", value: "BetGames.TV" },
  { name: "Betsolutions", value: "Betsolutions" },
  { name: "Booming Games", value: "Booming Games" },
  { name: "Booongo", value: "Booongo" },
  { name: "CT Gaming", value: "CT Gaming" },
  { name: "DLV_GAMES", value: "DLV_GAMES" },
  { name: "ELK", value: "ELK" },
  { name: "Endorphina", value: "Endorphina" },
  { name: "Evolution Gaming", value: "Evolution Gaming" }
 
];

const brandButtonsMiddle = [
  { name: "Game Art", value: "Game Art" },
  { name: "Evoplay", value: "Evoplay" },
  { name: "Ganapati", value: "Ganapati" },
  { name: "Golden Race", value: "Golden Race" },
  { name: "Habanero systems", value: "Habanero systems" },
  { name: "Igrosoft", value: "Igrosoft" },
  { name: "Lotto instantWin", value: "Lotto instantWin" },
  { name: "Microgaming", value: "Microgaming" },
  { name: "OneTouch", value: "OneTouch" },
  { name: "Platipus", value: "Platipus" },
  { name: "Playson", value: "Playson" },
  { name: "Blueprint", value: "Blueprint" },
  { name: "Ezugi", value: "Ezugi" },
  { name: "Quickspin", value: "Quickspin" },
  { name: "RTG Slots", value: "RTG Slots" },
  { name: "Yggdrasil", value: "Yggdrasil" },
  { name: "Redrakegaming", value: "Redrakegaming" },
  { name: "ReelNRG", value: "ReelNRG" },

  
];

const brandButtonsBottom = [
 
  { name: "Revolver Gaming", value: "Revolver Gaming" },
  { name: "Skywindgroup", value: "Skywindgroup" },
  { name: "Bgaming", value: "Bgaming" },
  { name: "Spinmatic", value: "Spinmatic" },
  { name: "Spinomenal", value: "Spinomenal" },
  { name: "Super Spade Games", value: "Super Spade Games" },
  { name: "TomHorn", value: "TomHorn" },
  { name: "Triple Cherry S.L.", value: "Triple Cherry S.L." },
  { name: "Thunderkick", value: "Thunderkick" },
  { name: "True Lab", value: "True Lab" },
  { name: "Betsoft", value: "Betsoft" },
  { name: "XPG", value: "XPG" },
  { name: "Spadegaming Malta", value: "Spadegaming Malta" },
  { name: "KA Gaming", value: "KA Gaming" },
  { name: "Belatra games", value: "Belatra games" },
  { name: "VIVO gaming", value: "VIVO gaming" },
  { name: "Green Jade Games", value: "Green Jade Games" }
];


export default class Casino extends Component {



  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    posts: [],
    livePosts: [],
    isActive: false,
    brandState: [],
    brandName: "",

    mobileArr: [],
    desktopArr: [],
    liveMobileArr: [],
    liveDesktopArr: [],

    logged: state.auth.logged,
    isToggleOn: [true],
    error: false,
    isOpen: true,
    page: 1,
    prevY: 1,
    PostsLoading: true,
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  handleClick = (value,name) => {
    let currState = []
    var togglePosition = this.state.isToggleOn;
    this.setState({ brandName: value });  
      if (value == "10" || value == "11" || value == "12" || value == "13" || value == "17" || value == "18" || value == "19"){
        // this.fetchPosts();
        if (parseInt(value) === 0) {
          currState = this.state.posts;
    
        } else {

          currState = this.state.posts.filter(
            post => post['Brand'] === parseInt(value)
          );
          
        }
      }
      else{

        currState = this.state.livePosts[0].filter(
          prov => prov["provider"] == value
        );
      }

    togglePosition[0] = false;
  
    
    this.setState({ desktopArr: currState,liveDesktopArr: currState,mobileArr: currState,liveMobileArr: currState, isActive: true });
  };

  handleObserver(entities, observer) {
    this.setState({ PostsLoading: true });
    const y = entities[0].boundingClientRect.y;
    for (var i = 0; i < 2; i++) {
    if (this.state.prevY > y) {
      const curPage = this.state.page + 1;
      this.fetchAdditionalPosts(curPage,this.state.brandName);
      this.setState({ page: curPage });
    }
  }
    this.setState({ prevY: y });
  }



  fetchPosts = async () => {
    var self = this;
    self.setState({ mobileArr: [], desktopArr: [] })
    const axios = require('axios');
    axios.get(API + '/get_casino_games')
      .then(function (response) {
        self.setState({ loading: false, posts: response.data, brandState: response.data })

        var desktopArr = [];
        var mobileArr = [];

        for (const item of response.data) {
          // alert(JSON.stringify(item.IsDesktop))
          if (item.IsDesktop == true) {
            desktopArr.push(item)
          }
          else {
            mobileArr.push(item)

          }
        }
        self.setState({ mobileArr: mobileArr, desktopArr: desktopArr })
      })
      .catch(function (error) {
        self.setState({ error: true });
        if (error.status > 299) {
          self.setState({ error: true });
        }
        else {
          self.setState({ error: true });
        }
      })
    //the rest of the code


  };

  fetchAdditionalPosts = async (page,provider) => {
    var self = this;
    self.setState({ PostsLoading: true, loading: true })
    const axios = require('axios');

    var posts_array = [];


    axios.get(API + '/live_slots_get_games?page=' + page)
      .then(function (response) {
        posts_array.push(response.data.items);

        var liveDesktopArr = [];
        var liveMobileArr = [];

        //get all pages
        for (const item of response.data.items) {
          //show only slots without live
          if (item.provider != 'eZugi' || item.provider != 'Betgames' || item.provider != 'XPG' || item.provider != 'Evolution' || item.provider != 'Vivogaming' || item.provider != 'Lotto Instant Win' || item.provider != 'Super Spade Games') {

            if (item.is_mobile != true) {
              liveDesktopArr.push(item)
              if (liveDesktopArr == []) {
                self.setState({ page: self.state.page += 1 })
              }
              self.setState({ liveDesktopArr: self.state.liveDesktopArr.concat(item) })
            }
            else {
              liveMobileArr.push(item)
              self.setState({ liveMobileArr: self.state.liveMobileArr.concat(item) })
            }
          
          }
        
        }

      })
      .catch(function (error) {
        self.setState({ error: true });
        if (error.status > 299) {
          self.setState({ error: true });
        }
        else {
          self.setState({ error: true });
        }
      })
    //the rest of the code

    self.setState({ loading: false, livePosts: posts_array, PostsLoading: false })

  };

  componentDidMount() {
    document.title = "Betdome|Casino"

    this.fetchPosts();
    for (var i = 1; i < 3; i++) {
      this.fetchAdditionalPosts(i,'');
    }
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);

 
  }



  render() {


    const loadingCSS = {
      height: "100px",
      margin: "30px",
      margin: "0 auto",
      backgroundColor: "#263238"
    };
    const loadingTextCSS = { display: this.state.PostsLoading ? "block" : "none", color: "white" };

    const renderList = this.state.desktopArr.map(card => (
    
      <BrowserView >

          <CardDeck style={{ margin: "0 auto"}}>
          <Card variant="card" className="box">
            <Card.Img variant="top" style={{ borderRadius: "8px" }} src={'https://50yo4fzxk02ujr25gm9y.s3-eu-west-1.amazonaws.com/casino/' + ((card.IsDesktop == true) ? 'desktop' : 'mobile') + '/' + ((card.Brand == 10) ? 'netent' : (card.Brand == 11) ? 'egt' : (card.Brand == 12) ? 'novomatic' : (card.Brand == 13) ? 'amatic' : (card.Brand == 17) ? 'wazdan' : (card.Brand == 18) ? 'pragmatic' : 'play_n_go') + '/' + card.LaunchId + '.jpg'} />
            <div className="overlay" >
              <div className="content_overlay">
                <Link className="fill-div" to={{
                  pathname: '/virtual_game',
                  state: { LaunchId: card.LaunchId, Brand: card.Brand }
                }} >{card.Name}</Link>
              </div>
            </div>
          </Card>
          </CardDeck>
          
          
          
        
      </BrowserView>
    ))

    const mobileList = this.state.mobileArr.map(card => (


      <MobileView>
        <CardDeck style={{ margin: "0 auto" }}>
          <Card variant="card" className="box">
            <Card.Img variant="top" style={{ borderRadius: "8px" }} src={'https://50yo4fzxk02ujr25gm9y.s3-eu-west-1.amazonaws.com/casino/' + ((card.IsMobile == true) ? 'mobile' : 'desktop') + '/' + ((card.Brand == 10) ? 'netent' : (card.Brand == 11) ? 'egt' : (card.Brand == 12) ? 'novomatic' : (card.Brand == 13) ? 'amatic' : (card.Brand == 17) ? 'wazdan' : (card.Brand == 18) ? 'pragmatic' : 'play_n_go') + '/' + card.LaunchId + '.jpg'} />
            <div className="overlay" >
              <div className="content_overlay">
                <Link className="fill-div" to={{
                  pathname: '/virtual_game',
                  state: { LaunchId: card.LaunchId, Brand: card.Brand }
                }} >{card.Name}</Link>
              </div>
            </div>
          </Card>
        </CardDeck>
      </MobileView>
    ))



    //live section API
    const liveList = this.state.liveDesktopArr.map(card => (



      <BrowserView>
        <CardDeck style={{ margin: "0 auto" }}>
          <Card style={{ width: "18rem" }} variant="card" className="box">
            <Card.Img variant="top" style={{ borderRadius: "8px" }} src={card.image} />
            <div className="overlay" >
              <div className="content_overlay">
                <Link className="fill-div" to={{
                  pathname: '/live_game',
                  state: { game_uuid: card.uuid }
                }} >{card.name}</Link>
              </div>
            </div>
          </Card>
        </CardDeck>
      </BrowserView>
    ))

    const liveMobileList = this.state.liveMobileArr.map(card => (


      <MobileView>
        <CardDeck style={{ margin: "0 auto" }}>
          <Card variant="card" className="box">
            <Card.Img variant="top" style={{ borderRadius: "8px" }} src={card.image} />
            <div className="overlay" >
              <div className="content_overlay">
                <Link className="fill-div" to={{
                  pathname: '/live_game',
                  state: { game_uuid: card.uuid }
                }} >{card.name}</Link>
              </div>
            </div>
          </Card>
        </CardDeck>
      </MobileView>

    ))





    return (
      <div>
        <Navbar />
        <Navbar_Bottom />
        <div className="body_div">
          <div className="casino-providers-bar">
            <Row style={{margin:"0 auto"}}>
            <Marquee speed="20" pauseOnHover="true" style={{width: "calc(135px * 15)"}}>
              <div className="sortingContainer" >
              {brandButtonsTop.map(({ name, value }) => (
                    <Button
                      variant="btn btn-outline_sort mr-2 "
                      key={name}
                      value={value}
                      active={this.state.isToggleOn[value]}
                      onClick={this.handleClick.bind(this, value,name)}>
                      {name}
                    </Button>
                  ))}

                </div>
                </Marquee>
              
              <Marquee direction="right" pauseOnHover="true" style={{}}>
              <div className="sortingContainerMiddle" >
           {brandButtonsMiddle.map(({ name, value }) => (
               
                    <Button
                      variant="btn btn-outline_sort mr-2 "
                      key={name}
                      value={value}
                      active={this.state.isToggleOn[value]}
                      onClick={this.handleClick.bind(this, value,name)}>
                      {name}
                    </Button>
           
                  ))}
                </div>
    
                </Marquee>
                <Marquee speed="25" pauseOnHover="true" style={{width: "calc(150px * 15)"}}>
                <div className="sortingContainerBottom" >

              {brandButtonsBottom.map(({ name, value }) => (
                    <p>
                    <Button
                      variant="btn btn-outline_sort mr-2 "
                      key={name}
                      value={value}
                      active={this.state.isToggleOn[value]}
                      onClick={this.handleClick.bind(this, value,name)}>
                      {name}
                    </Button>
                  </p>
                  ))}

                </div>
                
                </Marquee>
             
              
          
                    <div className="sortring-bar-2">
                    </div>
                    <div className="sortring-bar-3">
                      
                    </div>
           

             
             
            </Row>
          </div>
        </div>
        <div className="grid" style={{ backgroundColor: "#263238" }}>
          {this.state.loading ?
            <></>
            : renderList


          }
          {this.state.loading ?
            <></>
            : liveList
          }

          <MobileView>
            {mobileList}
            {liveMobileList}
          </MobileView>
          {this.state.logged ?
            <>
            </>
            :
            <ModalPopUp
              modalTitle="ERROR"
              isOpen={this.state.isOpen}
              modalMessage={"Something went wrong.Please try again later"}
              closeModal={this.closeModal}
            />
          }

        </div>

        <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
          style={loadingCSS}
        >

          <br />
          <div className="d-flex sweet-loading justify-content-center">
            <PropagateLoader css={override} size={15} color={"#FBC02D"} loading={this.state.PostsLoading} speedMultiplier={1.2} />
          </div>

        </div>

        <Footer />
      </div>

    );
  }


}

