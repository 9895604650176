import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";
import { Container,Row,Col,Form,Button} from 'react-bootstrap';

import API from "../utils/PlatformAPI";

class Contacts extends React.Component {

    constructor(props) {
        super(props)
		this.state = {
      email: "",
      name: "",
      message: ""
        }
    this.updateInputEmail = this.updateInputEmail.bind(this);
    this.updateInputName = this.updateInputName.bind(this);
    this.updateInputMessage = this.updateInputMessage.bind(this);
    }

    

    updateInputEmail = (e) => {
      this.setState({ email: e.target.value })
  }
  updateInputName = (e) => {
      this.setState({ name: e.target.value })
  }
  updateInputMessage = (e) => {
      this.setState({ message: e.target.value })
  }

    componentDidMount() {
    }

    sendContactForm = () => {
      var self = this;
      const axios = require('axios')
      axios.post(API + '/send_contact_form/', {email: self.state.email,name: self.state.name,message: self.state.message})
          .then(function (response) {
              if (response.status == false) {
                alert('error')
                  // Handle the error here...
              } else {
                  alert('message was sent')
                  // Handle the user data here...
              }

          })
  }

    render() {
        return (
            <div style={{color: "white",textAlign: "center"}}>
            <Navbar/>
            <Navbar_Bottom/>
            <div className="body_div">
              <br/>
            {/* <h1>CONTACT US</h1> */}
        
            <Container >
  <Row>
    <Col xs="12" lg="6"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></Col>
    <Col xs="12" lg="6"><Form>
    <Row>
    <Col>
      <Form.Control id="Email" onChange={this.updateInputEmail} placeholder="Email" />
    </Col>
    <Col>
      <Form.Control id="Username" onChange={this.updateInputName} placeholder="Name" />
    </Col>
  </Row>

  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label></Form.Label>
    <Form.Control as="textarea" id="Message" onChange={this.updateInputMessage} placeholder="Your message" rows={4} />
  </Form.Group>


  <Form.Group as={Row}>
    <Col sm={{ span: 0, offset: 0 }}>
      <Button onClick={this.sendContactForm} type="submit" className="App rounded-pill btn-primary">SEND A MESSAGE</Button>
    </Col>
  </Form.Group>
</Form>
</Col>
  </Row>
  </Container>
  </div>
  <Footer/>
            </div>
            )}
        }
        
export default Contacts;
        