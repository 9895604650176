import { createStore, applyMiddleware, compose } from "redux";
import reducer from '../reducers';

import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

import thunk from 'redux-thunk';
import { loadState, saveState } from './localstorage';
// import { configurateToken } from '../utils/axios.interceptors';

const composeEnhancers =  process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;


const middleware = applyMiddleware(createCookieMiddleware(Cookies),thunk);

const persistedStore = loadState();
let store



store = createStore(reducer, persistedStore, composeEnhancers(middleware));

store.subscribe(() => {
    saveState(store.getState());
  });

//   store = createStore(reducer, composeEnhancers(middleware));
// if (persistedStore !== undefined && persistedStore.auth.user !== null) {
//   store = createStore(reducer, persistedStore, composeEnhancers(middleware));
// } else { 
//   store = createStore(reducer, composeEnhancers(middleware));
// }

// store.subscribe(() => {
//   let stored = store.getState();
//   saveState({
//     auth: stored.auth,
//   });
// });

// if (store.getState().auth.token !== null) {
//   configurateToken(store.getState().auth.token);
// }

export default store;