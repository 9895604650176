import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import '../../../Main.css';
import { countries } from 'country-data-list';

// onChange={(e) => this.changeLogin(e)}
// onChange={(e) => this.changePassword(e)}

export const RegisterForm = (props) => (
    <div className="form-v10">
        <div className="page-content">
            <div className="form-v10-content">
                <form className="form-detail" action="#" method="post" id="myform">
                    <div className="form-left">
                        <h2>General Infomation</h2>
                        <div className="form-row">
                            <input type="text" name="your_username" id="your_username" className="input-text" onChange={props.changeUsername} required pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="Username" />
                        </div>
                        <div className="form-group">
                            <div className="form-row form-row-1">
                                <input type="text" name="first_name" id="first_name" className="input-text" onChange={props.changeFirstName} placeholder="First Name" required />
                            </div>
                            <div className="form-row form-row-2">
                                <input type="text" name="last_name" id="last_name" className="input-text" onChange={props.changeLastName} placeholder="Last Name" required />
                            </div>
                        </div>

                        <div className="form-row">
                            <input type="text" name="your_email" id="your_email" className="input-text" onChange={props.changeEmail} required pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}" placeholder="Your Email" />
                        </div>
                        <div className="form-group">
                            <div className="form-row form-row-1">
                                <input type="password" name="password" id="password" className="input-text" onChange={props.changePassword} placeholder="Password" required />
                            </div>
                            <div className="form-row form-row-2">
                                <input type="password" name="password" id="repeat" className="input-text" onChange={props.changePasswordRepeat} placeholder="Repeat Password" required />
                            </div>
                        </div>
                    </div>
                    <div className="form-right">
                        <h2>Contact Details</h2>

                        <div className="form-group">
                            <div className="form-row form-row-1">
                                <input type="tel" pattern="[0-9]*" placeholder="Zip Code" onChange={props.changeZip} max="99999" />
                            </div>
                            <div className="form-row form-row-2">
                                <select name="country" id="country_field" onChange={props.changeCountry}>
                                    <option disabled selected>Choose country:</option>
                                    {countries.all.map(({ name }) => {
                                        return <option>{name}</option>

                                    })}
                                </select>

                                <span className="select-btn">
                                    <i className="zmdi zmdi-chevron-down"></i>
                                </span>
                            </div>
                        </div>
                        <div className="form-row">
                            <select name="currency_field" onChange={props.changeCurrency}>
                                <option disabled selected value="choose currency">Choose currency:</option>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="TRY">TRY</option>
                                <option value="ZAR">ZAR</option>
                            </select>

                            <span className="select-btn">
                                <i className="zmdi zmdi-chevron-down"></i>
                            </span>
                        </div>
                        <div className="form-group">
                            <div className="form-row form-row-1">
                                <select name="phone" id="phone_field" placeholder="Phone code" onChange={props.changePhoneCode} required>
                                    <option disabled selected>Phone code:</option>
                                    {countries.all.map(({ countryCallingCodes, alpha2 }) => {
                                        return <><option className="option_count" disabled>{alpha2}</option><option>{countryCallingCodes}</option></>
                                    }
                                    )}
                                </select>

                            </div>
                            <div className="form-row form-row-2">
                                <input type="text" name="phone" className="phone" id="phone" onChange={props.changePhoneNumber} placeholder="phone number" required />
                            </div>

                        </div>
                        {/* <div className="form-group">
                            <div className="form-row form-row-3">
                                <input class="input-file" id="my-doc" type="file" />
                            </div>
                        </div> */}
                        <div className="form-checkbox" >
                            <label ><p style={{color: "#fff"}}>I do accept the <a href="/terms_and_coditions" className="text">Terms and Conditions</a> of your site.</p>
                                <input type="checkbox" name="checkbox" required />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="form-row-last">
                            <input type="submit" name="register" className="register" onClick={props.registerUser} value="Register Account" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
)