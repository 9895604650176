import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../../../Main.css';

// onChange={(e) => this.changeLogin(e)}
// onChange={(e) => this.changePassword(e)}

export const LoginPopup = (props) => (

  <Modal show={props.isOpen} size="sl" aria-labelledby="contained-modal-title-vcenter" onHide={props.closeModal} centered>
    <Modal.Header className="modal_head">
      <Modal.Title >LOGIN</Modal.Title>
      <br/>
    </Modal.Header>
    <Modal.Body >
      <div className="input-group mb-3">
        <input type="text" className="form-control input_user_info " value={props.userLogin} onChange={props.changeLogin} placeholder="Username" aria-label="Username" id="username" aria-describedby="basic-addon1" />
      </div>
      <div className="input-group mb-3">
        <input type="password" className="form-control input_user_info " value={props.userPass} onChange={props.changePassword} placeholder="Password" aria-label="Password" id="password" aria-describedby="basic-addon1" />
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button
        className="rounded-pill center-div"
        variant="secondary"
        onClick={props.closeModal}>
        Close
      </Button>

      <Button
        className="rounded-pill center-div"
        id="login-btn"
        variant="primary"
        onClick={props.sendUserData}
      >
        {props.isLoading ? 'Loading...' : 'Log in'}
      </Button>
      <p className="alert-text">{props.errorText && props.errorText}</p>

    </Modal.Footer>
  </Modal>

)

function format() {
  var fnf = document.getElementById('cno').value;
  return fnf.toString().replace(/\d{4}(?=.)/g, '$& ');
}
export const DepositPopup = (props) => (

 
  
  <Modal show={props.isDeposit} size="ml" onHide={props.closeDeposit} className="deposit-modal">
    <div className="payment-card mx-auto" >
      <p className="heading" style={{marginLeft: "-2px"}}>MAKE DEPOSIT</p>
      <form className="card-details ">
        <div className="form-group mb-0">
          <p className="text-warning mb-0" style={{marginLeft: "-1px"}}>Card Number</p><input type="text" onChange={format} className="input-payment" style={{ float: "left", marginTop: "18px" }} name="card-num"  placeholder="1234 5678 9012 ****" size="30" id="cno" minLength="19" maxLength="19" />   <img src="https://icon-library.com/images/visa-mastercard-icon/visa-mastercard-icon-9.jpg" width="110px" height="40px" />
        </div>
        <div className="form-group mb-0">
        <br />
        <div className="card_row">
  <div className="card_column">
          <p className="text-warning mb-0" style={{marginLeft: "-1px"}}>Sum</p> <input className="input-payment" type="number" name="name" id="pop_sum" min="0" placeholder="Sum" size="20" onChange={props.changeDepositSum} />
          </div>
  <div className="card_column" style={{marginTop: "3px",marginLeft: "45px" }}>
          <p className="text-warning mb-0" style={{marginLeft: "-1px"}}>Currency</p> <input className="input-payment" style={{ float: "left", marginTop: "-3px"}} type="text" name="name" id="pop_currency" maxLength="3"  placeholder="USD" value="USD" size="4" onChange={props.changeDepositCurrency}/>
          </div>
</div>
          <br /> 
          <div className="form-group mb-0">
          <div className="row d-flex">
          
          <div className="col-sm-5" > <Button className="rounded-pill btn-primary"  onClick={props.sendDepositData}> <FontAwesomeIcon icon={faArrowRight}  /> SUBMIT</Button> </div>
          <br /> 
          </div>
        </div>
         <br /> 
         
        </div>
       
      </form>
    </div>
  </Modal>

)