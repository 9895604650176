import { combineReducers } from 'redux'

import auth from './auth.reducer'
import deposit from './deposit.reducer'

const reducer = combineReducers({
  auth,
  deposit
})

export default reducer