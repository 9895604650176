import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
import Footer from "../components/Footer";

import axios from "axios";
import { ModalPopUp } from '../components/Header/Buttons/ModalPopUp'

import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";
import store from '../redux/store';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const state = store.getState();

const API = "https://betdrome.com/api";

class VirtualSports extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
    token: '',
    error: false,
    isOpen: true,
    frame_data: [],
    logged: state.auth.logged,
    token: [],
    user_id: state.auth.userId
  }

  openModal = () => this.setState({ isOpen: true});
  closeModal = () => this.setState({ isOpen: false });

  fullscreen() {
    // check if fullscreen mode is available
    if (document.fullscreenEnabled || 
      document.webkitFullscreenEnabled || 
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled) {
      
      // which element will be fullscreen
      var iframe = document.querySelector('#container_frame iframe');
      // Do fullscreen
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    }
    else {
      document.querySelector('.error').innerHTML = 'Your browser is not supported';
    }
  }
  
  fullscreenChange() {
    if (document.fullscreenEnabled ||
         document.webkitIsFullScreen || 
         document.mozFullScreen ||
         document.msFullscreenElement) {
      console.log('enter fullscreen');
    }
    else {
      console.log('exit fullscreen');
    }
    // force to reload iframe once to prevent the iframe source didn't care about trying to resize the window
    // comment this line and you will see
  }

  getFrame = async () => {
    var self = this;
    const axios = require('axios')
    axios.get(API + '/update_virtual_token/')
      .then(function (response) {
        if (response.status === false) {
          } else {  
            try{
            self.setState({token: toString(response.data.token)})
           const fetchFrame = async () => {
              const axios = require('axios');
              // const iframe = document.getElementById('virtual_frame');
              axios.get(API + '/get_virtual_frame/?user_id='+ self.state.user_id +'&token='+response.data.token)
              .then(function (response) {
                self.setState({frame_data: response.data,loading: false,error: false})
                // iframe.outerHTML = response.data
                //  iframe.write(response.data);
                })
                .catch(function (error) {
                  self.setState({error: true});
                if(error.status>299){
                  self.setState({error: true});
                }
                else{
                 self.setState({error: true});
                }
              })
              //the rest of the code
          
          
            };
            fetchFrame();
            }
            catch{
            }
            // localStorage.removeItem("balance")
          // Handle the user data here...
          }
        
      })
  }

  
  componentDidMount() {
    document.title = "Betdome|Virtual Sports"
    this.getFrame();


    document.addEventListener('keydown', function (e) {
      console.log('key press' + e.keyCode);
    });
    // detect enter or exit fullscreen mode
    document.addEventListener('webkitfullscreenchange', this.fullscreenChange);
    document.addEventListener('mozfullscreenchange', this.fullscreenChange);
    document.addEventListener('fullscreenchange', this.fullscreenChange);
    document.addEventListener('MSFullscreenChange', this.fullscreenChange);
      
      
  }








  render() {
    return (
      <div style={{ backgroundColor: "#263238" }}>
        <Navbar />
        <Navbar_Bottom />
        <div style={{ minHeight: "180px",backgroundColor: "#1d2227" }}>


          {this.state.loading ?
            <div className="d-flex justify-content-center" style={{ position: "absolute", margin: "auto", width: "100%", padding: "20px",top: "24%" }}>

              <div className="sweet-loading">
              <PropagateLoader css={override} size={15} color={"#FBC02D"} loading={this.state.loading} speedMultiplier={1.2} />
              </div>
            </div>
            :

            <div className="embed-responsive embed-responsive-16by9" style={{minHeight: "900px"}}>
            <div dangerouslySetInnerHTML={{__html:  this.state.frame_data}} />

            </div>
          }
          {this.state.logged ?
           <>
           </> 
          :
         
          <ModalPopUp
          modalTitle="ERROR"
          isOpen={this.state.isOpen}
          modalMessage={"Something went wrong.Please try again later"}
          closeModal={this.closeModal}
        />
          }
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // alertText: state.alert.text,
  // alertError: state.alert.error,
})

const mapDispatchToProps = (dispatch) => ({
  // displayAlert: (data) => {
  //     dispatch(showAlert(data))
  // },
  // resetAlert: (data) => {
  //     dispatch(clearAlert(data))
  // }
})

export default connect(mapStateToProps, mapDispatchToProps)(VirtualSports);