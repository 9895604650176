import axios from 'axios';
import Cookies  from 'js-cookie';
import {encode,decode} from 'string-encode-decode'
import API from "../utils/PlatformAPI";

// import { getCookie, setCookie, expireCookie } from 'redux-cookie';
export const SEND_DEPOSIT_REQ = "SEND_DEPOSIT_REQ";
export const GET_DEPOSIT_RES = "GET_DEPOSIT_RES";
export const GET_DEPOSIT_ERR = "GET_DEPOSIT_ERR";



export const deposit = (data) => (dispatch) => {
  dispatch({ type: SEND_DEPOSIT_REQ,isLoading: true})
  const options = {
    headers: {'Authorization': 'Token ' + decode(Cookies.get('J_UT'))}
  };
  axios.post(API + '/deposit/',{
    deposit_sum: parseInt(data.deposit),currency: toString(data.currency)},options)
    .then(res => {
      // console.log('§%%$%$§ DATA from DEPOSIT', data.deposit)
      // console.log('§%%$%$§ DATA from CURRENCY', data.currency)
      // setCookie("J_UT", res.data.token);
      
      window.location.reload();

       
    })
    
    .catch(error => {
      dispatch({
        type: GET_DEPOSIT_ERR,
        error: 'Wrong login or password '
      })
    })
};

                                                                                                                            