import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
// import axios from "axios";

import Cookies from 'js-cookie';

import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import { decode } from 'string-encode-decode'
import Footer from "../components/Footer";
import API from "../utils/PlatformAPI";

import store from '../redux/store';

const state = store.getState();

export default class VirtualGame extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    session: [],
    game_code: [],
    brand_code: [],
    frame_data: [],
    userId: state.auth.userId,
    value: this.props.location.state,
  };

  fullscreen() {
    // check if fullscreen mode is available
    if (document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled) {

      // which element will be fullscreen
      var iframe = document.querySelector('#container_frame iframe');
      // Do fullscreen
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    }
    else {
      document.querySelector('.error').innerHTML = 'Your browser is not supported';
    }
  }

  fullscreenChange() {
    if (document.fullscreenEnabled ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement) {
      console.log('enter fullscreen');
    }
    else {
      console.log('exit fullscreen');
    }
    // force to reload iframe once to prevent the iframe source didn't care about trying to resize the window
    // comment this line and you will see
  }

  getGame = (user_id) => {
    this.setState({ loading: true });
    let selectedCardId = this.props.location.state.LaunchId;
    let selectedBrandId = this.props.location.state.Brand;

    const fetchSession = async () => {
      var self = this;
      const axios = require('axios');
      axios.get(API + '/update_casino_token/' + '?userid=' + self.state.userId)
        .then(function (response) {
          const iframe = document.getElementById('game_frame').contentDocument;
          var params = { game_code: selectedCardId, brand_code: selectedBrandId, session: response.data.Values.SessionId }
          axios.post(API + '/get_casino_frame/', params)
            .then(function (response) {
              self.setState({ frame_data: response.data, loading: false })
              iframe.write(response.data);
            })
            .catch(function (error) {
              self.setState({ error: true, loading: false });
              if (error.status > 299) {
                self.setState({ error: true, loading: false });
              }
              else {
                self.setState({ error: true, loading: false });
              }
            })
          //the rest of the code

        })
        .catch(function (error) {
          console.log(error);
        });
      //the rest of the code
    };


    fetchSession();

  }

  balanceUpdate = async () => {
    var self = this;
    const axios = require('axios')
    const options = {
      headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
    };
    axios.get(API + '/get_balance', options)
      .then(function (response) {
        if (response.status === false) {
        } else {
          try {
            self.setState({ user_id: toString(response.data.user_id) })
            self.getGame(response.data.user_id);
          }
          catch {
          }
          // Handle the user data here...
        }

      })
  }


  componentDidMount() {
    this.setState({ loading: true });
    this.balanceUpdate();


    document.addEventListener('keydown', function (e) {
      console.log('key press' + e.keyCode);
    });
    // detect enter or exit fullscreen mode
    document.addEventListener('webkitfullscreenchange', this.fullscreenChange);
    document.addEventListener('mozfullscreenchange', this.fullscreenChange);
    document.addEventListener('fullscreenchange', this.fullscreenChange);
    document.addEventListener('MSFullscreenChange', this.fullscreenChange);


  }





  render() {



    return (
      <div style={{ backgroundColor: "#1d2227" }}>
        <Navbar />
        <Navbar_Bottom />
        <div id="container_frame">
          <button className="fullscreen_button" onClick={this.fullscreen}><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpdGUL_ahk2qO1hR8C8zgZPF-64zPUtygQ0WGopi0yl1S17EgxAfrBlRhhrAZrbYgAMU4&usqp=CAU" style={{ width: "25px", height: "25px" }} alt="fullscreen" onClick={this.myfunction} /></button>
          <iframe id="game_frame" border="0" framespacing="0" frameBorder="0" style={{ backgroundColor: "#000", display: "block", margin: "0 !important"}} src={this.frame_data} />
          <div width="100%" height="750px" id="frame_game">
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}
