import React, { useState, useRef } from "react";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import Cookies  from 'js-cookie';

// import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import {decode} from 'string-encode-decode'
import API from "../utils/PlatformAPI";



const Layout = styled.div``;



const StyledControlFields = styled.div`
  display: flex;
`;

let Item = styled.div`
  display: flex;
  color: #fff;
  min-width: 350px;
  width: 100%;
  height: 380px;
  border-radius: 20px !important;
  background-color: "#1d2227";
  background-image: url('${props => props.image}');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; `;



const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 420, itemsToShow: 0.75 },
  { width: 650, itemsToShow: 0.85, itemsToScroll: 4 },
  { width: 868, itemsToShow: 1.5 },
  { width: 1200, itemsToShow: 2.8 }
];


let first_image = "https://image.freepik.com/free-vector/casino-poker-cards-roulette-wheel-banner_91128-250.jpg"
let secound_image = "https://image.freepik.com/free-vector/casino-poker-cards-roulette-wheel-banner_91128-250.jpg"

let third_image = 'https://media.istockphoto.com/photos/rows-of-casino-slot-machines-picture-id883608066?k=6&m=883608066&s=612x612&w=0&h=Y5XnTv6m5SqJg_ORx5x_xMihIXz5EwBtjxd_4deIpbE='
let fourth_image = 'https://media.istockphoto.com/photos/rows-of-casino-slot-machines-picture-id883608066?k=6&m=883608066&s=612x612&w=0&h=Y5XnTv6m5SqJg_ORx5x_xMihIXz5EwBtjxd_4deIpbE='



function get_banners(){
  var self = this;
  const axios = require('axios')
  const options = {
    headers: {'Authorization': 'Token ' + decode(Cookies.get('J_UT'))}
  };
   axios.get(API + '/get_banners',options)
    .then(function (response) {
      if (response.status === false) {
        // Handle the error here...
        } else { 
          try{
        // document.getElementById('0').image = JSON.stringify(response.data.banner_data[0][0]).replace(/["']/g, "");
        // secound_image = JSON.stringify(response.data.banner_data[1][0]).replace(/["']/g, "");
      
        document.getElementById('first_title').textContent = JSON.stringify(response.data.banner_data[0][1]).split('"').join('');
        document.getElementById('first_desc').textContent = JSON.stringify(response.data.banner_data[0][2]).split('"').join('');
        document.getElementById('first_link').setAttribute('href', JSON.stringify(response.data.banner_data[0][3]).split('"').join(''));
        
        document.getElementById('secound_title').textContent = JSON.stringify(response.data.banner_data[1][1]).split('"').join('');
        document.getElementById('secound_desc').textContent = JSON.stringify(response.data.banner_data[1][2]).split('"').join('');
        document.getElementById('secound_link').setAttribute('href', JSON.stringify(response.data.banner_data[1][3]).split('"').join(''));

        document.getElementById('third_title').textContent = JSON.stringify(response.data.banner_data[2][1]).split('"').join('');
        document.getElementById('third_desc').textContent = JSON.stringify(response.data.banner_data[2][2]).split('"').join('');
        document.getElementById('third_link').setAttribute('href', JSON.stringify(response.data.banner_data[2][3]).split('"').join(''));

        document.getElementById('fouth_title').textContent = JSON.stringify(response.data.banner_data[3][1]).split('"').join('');
        document.getElementById('fouth_desc').textContent = JSON.stringify(response.data.banner_data[3][2]).split('"').join('');
        document.getElementById('fouth_link').setAttribute('href', JSON.stringify(response.data.banner_data[3][3]).split('"').join(''));
          }
          catch{
            
          }

        // Handle the data here...
        }
      
    })
}




// initializing games(bad code)
var first_banner = <div className="card-body" >
  <h5 className="card-title" id="first_title"></h5>
  <p className="card-text" id="first_desc" ></p>
  <a className="btn btn-outline" id="first_link">Play</a>
</div>

var secound_banner = <div className="card-body">
  <h5 className="card-title" id="secound_title"></h5>
  <p className="card-text" id="secound_desc"></p>
  <a href="#" className="btn btn-outline" id="secound_link">Play</a>
</div>

var third_banner = <div className="card-body">
  <h5 className="card-title" id="third_title"></h5>
  <p className="card-text" id="third_desc"></p>
  <a href="#" className="btn btn-outline" id="third_link">Play</a>
</div>

var fouth_banner = <div className="card-body">
  <h5 className="card-title" id="fouth_title">HI THERE</h5>
  <p className="card-text" id="fouth_desc">seems like it`s working!!</p>
  <a href="#" className="btn btn-outline" id="fouth_link">Chunk!</a>
</div>

  

function Carousel_comp () {
  get_banners();
  const [items, setItems] = useState([first_banner, secound_banner, third_banner, fouth_banner]);
  const [itemsToShow, setItemsToShow] = useState(5);
  const [showArrows, setShowArrows] = useState(false);
  const [pagination, setPagination] = useState(true);
  const carouselRef = React.useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(items.length);
    }
  };

  return (
<div>
    <Layout>
      <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={4000} showArrows={showArrows} pagination={pagination} ref={carouselRef} onPrevStart={onPrevStart} onNextStart={onNextStart} disableArrowsOnEnd={false} >
        <Item image={first_image} >{items[0]}</Item>
        <Item image={secound_image} >{items[1]}</Item>
        <Item image={third_image} >{items[2]}</Item>
        <Item image={fourth_image} >{items[3]}</Item>

      </Carousel>

    </Layout>
    </div>
  );
};

export default Carousel_comp;