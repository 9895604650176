import React, { Component } from 'react';
import  Navbar  from '../components/Navbar';
import  Navbar_Bottom  from '../components/Navbar_bot';
import {decode} from 'string-encode-decode'
import Footer from "../components/Footer";
import Cookies  from 'js-cookie';
import { ModalPopUp } from '../components/Header/Buttons/ModalPopUp'
import store from '../redux/store';

const state = store.getState();

export default class Live_In_Play extends Component {

    constructor(props) {
        super(props);

      }

      state = {
        loading: false,
        isOpen: true,
        logged: state.auth.logged
      };
      closeModal = () => this.setState({ isOpen: false });

   


    componentDidMount() {
      this.setState({ loading: true});      
      
      
        }



    

    render() {
        

        
        return (
            <div>
            <Navbar /> 
            <Navbar_Bottom />
            {this.state.logged ?
             <>
             </>
               :
         
          <ModalPopUp
          modalTitle="ERROR"
          isOpen={this.state.isOpen}
          modalMessage={"Something went wrong.Please try again later"}
          closeModal={this.closeModal}
      /> 
            }
            <div className="embed-responsive embed-responsive-16by9" style={{minHeight: "900px",overflow: "auto",WebkitOverflowScrolling: "touch !important",width: "100%"}}>
            <iframe width="100%" scrolling="yes"  height="900px" id="game_frame" border="0" framespacing="0" frameBorder="0" style={{backgroundColor: "#263238",display: "block",margin: "0",overflow: "scroll !important"}} src={"https://sportsbook.betdrome.com/?lng=en&authToken=" + decode(Cookies.get('J_UT')) + "&isLine=false&themeMode='dark'"}/>
            </div>
            {/* {this.state.session.map(frame_data => (
            <div className="SessionId">{frame_data}</div>
            )
        )} */}
            <Footer />
            </div>
            );
        }
        }
        