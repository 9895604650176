import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../../../Main.css';

export const ModalPopUp = (props) => (

  <Modal show={props.isOpen} size="sm"  aria-labelledby="contained-modal-title-vcenter" animation={true} centered onHide={props.closeModal} >
    <Modal.Header className="modal_alert_head">
      <Modal.Title >{props.modalTitle}</Modal.Title>
      <br/>
    </Modal.Header>
    <Modal.Body  style={{borderRadius: "0 0 20px 20px"}}>
      <div className="input-group mb-3">
      <h6>{props.modalMessage}</h6>
      </div>
      <div className="center-div">
      <Button
        className=""
        variant="primary"
        onClick={props.closeModal}
      >
       OKAY
      </Button>
      </div>
    </Modal.Body>

 
  </Modal>

)