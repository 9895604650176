import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { HeaderSearchbar } from './Header/HeaderSearchbar';
import { Route, Switch, withRouter } from "react-router-dom";

import SearchResultsPage from "../pages/SearchResultsPage";
import RouteNotFound from "../pages/RouteNotFound";

class Navbar_Bottom extends React.Component {

  state = {
    searchText: ""
  };

  //router memory
  handleRoute = route => () => {
    this.props.history.push({ pathname: route });
  };

  //search input change state
  handleSearchInput = event => {
    this.setState({
      searchText: event.target.value
    });
  };

  //on serch submit
  handleSearchSubmit = () => {
    if (this.state.searchText) {
      let text = this.state.searchText;
      this.setState({ searchText: "" })
      this.props.history.push({
        pathname: "/search_results",
        state: { searchText: text }
      });
    } else {
      alert("Please enter some search text!");
    }
  };

  handleSearchKeyUp = event => {
    event.preventDefault();
    if (event.key === 'Enter' && event.keyCode === 13) {
      this.handleSearchSubmit();
    }
  }

  handleFormSubmit = e => e.preventDefault();


  render() {
    return (
      <>
        <nav className="navbar navbar-dark navbar-bottom" style={{ backgroundColor: "#263238" }}>



          <Col xs={6} sm={6} lg={3} style={{ height: "42px" }} className="searchbar-container">
            <div className="searchbar">
              <input onChange={this.handleSearchInput} value={this.state.searchText} onKeyUp={this.handleSearchKeyUp} className="search_input" type="text" placeholder="Search..." />
              <a className="search_icon" onClick={this.handleSearchSubmit} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faSearch} /></a>
            </div>

          </Col>
          <Col sm={1} xs={1} lg={6} >
            <div className="breadclumbs"><h2 style={{ color: "white", textAlign: "left" }}>{this.props.location.pathname.toUpperCase().substring(1).replace("_", " ").replace("_", " ").includes("LIVE GAME") ? <Button className="App rounded-pill btn-secondary"><a href="/live_casino" style={{ textDecoration: "none", color: "white" }}><FontAwesomeIcon icon={faArrowLeft} /> back to lobby</a></Button> : this.props.location.pathname.toUpperCase().substring(1).replace("_", " ").replace("_", " ").includes("VIRTUAL GAME") ? <Button className="App rounded-pill btn-secondary"><a href="/casino" style={{ textDecoration: "none", color: "white" }}><FontAwesomeIcon icon={faArrowLeft} /> back to lobby</a></Button> : this.props.location.pathname.toUpperCase().substring(1).replace("_", " ").replace("_", " ")}</h2></div>
          </Col>

          {/* href="javascript: history.go(-1);history.go(-1);" */}

          <Col xs={2} sm={2} lg={2} >
            <div className="btn-toolbar-lang" role="toolbar" aria-label="Toolbar with button groups" style={{ float: "right" }}>



              <div className="btn-group" role="group" aria-label="First group" >
                <div className="dropdown" >

                  <DropdownButton variant="App rounded-pill dropdown-toggle btn-secondary" title="EN" id="bg-nested-dropdown">
                    <Dropdown.Item eventkey="1">EN</Dropdown.Item>
                    <Dropdown.Item eventkey="2" disabled>IL</Dropdown.Item>
                  </DropdownButton>

                </div>
              </div>


            </div>
          </Col>
        </nav>

      </>

    );
  }
}

export default withRouter(Navbar_Bottom)