import React from 'react'
import {  Dropdown, DropdownButton, Modal,Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import Deposit from '../../Header/Buttons/assets/deposit.png'
import '../../../Main.css';




export const AccountDropdown = (props) => (
    <div className="account-buttons">
    <DropdownButton  style={{float: "left",marginRight: "8px"}} variant="App dropdown-toggle btn-primary"
        eventkey={666}
        title={
            <span>{props.user_balance} <FontAwesomeIcon icon={faUser} /></span>
        }>
        <Dropdown.Item eventkey='1' href="/my_profile"><i className="fa fa-envelope fa-fw"></i> User Profile</Dropdown.Item>
        <Dropdown.Item eventkey='2' href="/bets_history"><i className="fa fa-gear fa-fw"></i> Bets list</Dropdown.Item>
        <Dropdown.Item eventkey='3' href="/transactions_history"><i className="fa fa-gear fa-fw"></i> Transactions</Dropdown.Item>
        <Dropdown.Item divider="true" />
        <Dropdown.Item eventkey='6' onClick={props.set_invisible} ><i className="fa fa-sign-out fa-fw"></i> Logout</Dropdown.Item>
    </DropdownButton>
    <Button className="App btn-deposit " onClick={props.openDeposit}>deposit<img src={Deposit} style={{paddingBottom: "3px"}} width="22px" height="22px"/></Button>


    </div>
 
)