import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Navbar_Bottom from '../components/Navbar_bot';
// import axios from "axios";

import Cookies from 'js-cookie';

import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import { decode } from 'string-encode-decode'
import Footer from "../components/Footer";
import API from "../utils/PlatformAPI";

import store from '../redux/store';

const state = store.getState();

export default class LiveGame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.location.state,
    }
  }

  state = {
    session: [],
    game_code: [],
    brand_code: [],
    frame_url: [],
    userId: state.auth.userId,
  };

  fullscreen() {
    // check if fullscreen mode is available
    if (document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled) {

      // which element will be fullscreen
      var iframe = document.querySelector('#container_frame iframe');
      // Do fullscreen
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    }
    else {
      document.querySelector('.error').innerHTML = 'Your browser is not supported';
    }
  }

  fullscreenChange() {
    if (document.fullscreenEnabled ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement) {
      console.log('enter fullscreen');
    }
    else {
      console.log('exit fullscreen');
    }
    // force to reload iframe once to prevent the iframe source didn't care about trying to resize the window
    // comment this line and you will see
  }

  getGame = () => {
    var self = this;
    self.setState({ loading: true });
    const axios = require('axios');
    axios.get(API + '/live_slots_init_game', { params: { game_uuid: this.props.location.state.game_uuid, player_id: state.auth.userId } })
      .then(function (response) {
        // alert(response.data.url)
        self.setState({ frame_url: response.data.url })

        //the rest of the code

      })
      .catch(function (error) {
        console.log(error);
      });
    //the rest of the code
  }

  balanceUpdate = async () => {
    var self = this;
    const axios = require('axios')
    const options = {
      headers: { 'Authorization': 'Token ' + decode(Cookies.get('J_UT')) }
    };
    axios.get(API + '/get_balance', options)
      .then(function (response) {
        if (response.status === false) {
        } else {
          try {
            self.setState({ user_id: toString(response.data.user_id), loading: false })
            self.getGame();
          }
          catch { }
          // localStorage.removeItem("balance")
          // Handle the user data here...
        }

      })
  }


  componentDidMount() {
    this.setState({ loading: true });
    this.balanceUpdate();
    // var button = document.querySelector('#container_frame .button');
    // button.addEventListener('click', this.fullscreen);
    // when you are in fullscreen, ESC and F11 may not be trigger by keydown listener. 
    // so don't use it to detect exit fullscreen
    document.addEventListener('keydown', function (e) {
      console.log('key press' + e.keyCode);
    });
    // detect enter or exit fullscreen mode
    document.addEventListener('webkitfullscreenchange', this.fullscreenChange);
    document.addEventListener('mozfullscreenchange', this.fullscreenChange);
    document.addEventListener('fullscreenchange', this.fullscreenChange);
    document.addEventListener('MSFullscreenChange', this.fullscreenChange);
  }





  render() {



    return (
      <div style={{ backgroundColor: "#1d2227" }}>
        <Navbar />
        <Navbar_Bottom />
        <div id="container_frame">
          <button className="fullscreen_button" onClick={this.fullscreen}><img src="https://cdn-icons-png.flaticon.com/512/3580/3580116.png" style={{ width: "25px", height: "25px" }} alt="fullscreen" onClick={this.myfunction} /></button>
          <iframe id="game_frame" border="0" framespacing="0" frameBorder="0" style={{ backgroundColor: "#1d2227", display: "block", margin: "0", overflowY: "hidden", overflowX: "hidden" }} src={this.state.frame_url} />
        </div>

        <Footer />
      </div>
    );
  }
}
