import React from 'react'
import { NavLink } from 'react-router-dom'

export const HeadeMenu = (props) => (
    <div className="collapse navbar-collapse" id="navbarToggler">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0" >
            <li className="nav-item">
            <NavLink className="nav-link" to="/live_in_play" >Live-in-play</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link"  to="/sportsbook">Sportsbook</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to='/casino'>Casino</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to='/live_casino'>Live Casino</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to="/virtual_sports">Virtual Sports</NavLink>
            </li>
            <li className="nav-item">
            <NavLink className="nav-link" to="/promotions">Promotions</NavLink>
            </li>
        </ul>
    </div>
)